import React, {useState} from 'react';
import { IonSelect,IonSelectOption,IonInput,IonToast } from '@ionic/react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import '../../../components/css/Register.css';
import url from '../../../axios.js';
import axios from 'axios';
import limitIcon from '../../../images/icons/limit.svg';

function GamesLimit(props){
  const limits = ["10","20","30","40","50",'60','70','80'];
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [monthly, setMonthly] = useState(checkCustom(props.data.userData.month_limit));
  const [weekly, setWeekly] = useState(checkCustom(props.data.userData.week_limit));
  const [daily, setDaily] = useState(checkCustom(props.data.userData.day_limit));
  const [customMonthly, setCustomMonthly] = useState(props.data.userData.month_limit);
  const [customWeekly, setCustomWeekly] = useState(props.data.userData.week_limit);
  const [customDaily, setCustomDaily] = useState(props.data.userData.day_limit);
  const [depositLimit, setDepositLimit] = useState(0);
  const [customDeposit, setCustomDeposit] = useState(0);
  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [customWithdraw, setCustomWithdraw] = useState(0);
  function checkCustom(value) {
    return limits.indexOf(value) !== -1 ? value : 'custom';
  }
  let checkDaily = (e) => {
    setDaily(e);
  };
  let checkWeekly = (e) => {
    setWeekly(e);
  }
  let checkMonthly = (e) => {
    setMonthly(e);
  }
  let checkDeposit = (e) => {
    setDepositLimit(e);
  }
  let checkWithdraw = (e) => {
    setWithdrawLimit(e);
  }
    function save() {
       if (monthly === undefined || monthly === "") {
         setMonthly('');
      } if (daily === undefined || daily === "") {
         setDaily('');
      } if (weekly === undefined || weekly === "") {
         setWeekly('');
      } if (  monthly !== undefined &&
        monthly !== '' &&
        daily !== undefined &&
        daily !== '' &&
        weekly !== undefined &&
        weekly !== '') {
          var data = {
            day_limit: daily === 'custom' ? customDaily : daily.toString(),
            week_limit: weekly === 'custom' ? customWeekly : weekly.toString(),
            month_limit: monthly === 'custom' ? customMonthly : monthly.toString(),
           };
           axios({
                   method: 'post',
                   url: url + '/api/update-profile',
                   headers: {
                        'Authorization': `Bearer ${props.data.token}`,
                    },
                   data: data,
                 })
                 .then(res => {
                   if (res.data.status === 1) {
                     props.updateUser(props.data.token)
                     setShowToast1(true);
                   }
                 })
                 .catch(error => {
                   /*422*/
                   setShowToast2Text(error.response.data)
                   setShowToast2(true);
                  })

      }
    }
    return (
      <div className="deposit-money">
        <div className="shade">
          <p className="top-shade">
            <img alt='games limit' src={limitIcon} />
            <span>Account</span>
          </p>
          <p className="bottom-shade">Games Limit</p>
        </div>
        <p className="title">
          <span>Account Details</span>
        </p>
        <p className="title-2">Games Limit</p>
        <div className='account-details-container games-limit'>
          <div className='step-input-container'>
            <p className='step-input-title'>Daily Limit</p>
            <div className='custom-block'>
              <IonSelect lines="none"
              onIonChange={e => checkDaily(e.detail.value)}
              value={daily} placeholder="Not Specified"
              className={'header-search custom-input ' + (daily !== undefined && daily === '' ? "wrong" : '')}
              interface="popover">
                {limits.map(el => {
                  return(
                  <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                })}
                <IonSelectOption className='hide' value={"custom"}>Custom Limit</IonSelectOption>
              </IonSelect>
              {
                daily === 'custom' &&
                  <IonInput
                    className={'header-search mobilecode custom-limit '}
                    type="tel"
                    value={customDaily}
                    placeholder="Custom limit"
                    onIonChange={e => setCustomDaily(e.detail.value)}>
                  </IonInput>
              }
            </div>
          </div>
          <div className='step-input-container'>
            <p className='step-input-title'>Weekly Limit</p>
            <div className='custom-block'>
              <IonSelect
              lines="none" onIonChange={e => checkWeekly(e.detail.value)}
              value={weekly}
              placeholder="Not Specified"
              className={'header-search custom-input ' + (weekly !== undefined && weekly === '' ? "wrong" : '')}
              interface="popover">
                {limits.map(el => {
                  return(
                  <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                })}
                <IonSelectOption className='hide' value={"custom"}>Custom Limit</IonSelectOption>
              </IonSelect>
              {
                weekly === 'custom' &&
                  <IonInput
                    className={'header-search mobilecode custom-limit'}
                    type="tel"
                    value={customWeekly}
                    placeholder="Custom limit"
                    onIonChange={e => setCustomWeekly(e.detail.value)}>
                  </IonInput>
              }
            </div>
          </div>
          <div className='step-input-container'>
            <p className='step-input-title'>Monthly Limit</p>
            <div className='custom-block'>
              <IonSelect lines="none"
              onIonChange={e => checkMonthly(e.detail.value)}
              value={monthly}
              placeholder="Not Specified"
              className={'header-search custom-input ' + (monthly !== undefined && monthly === '' ? "wrong" : '')}
              interface="popover">
                {limits.map(el => {
                  return(
                  <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                })}
                <IonSelectOption className='hide' value={"custom"}>Custom Limit</IonSelectOption>
              </IonSelect>
              {
                monthly === 'custom' &&
                  <IonInput
                    className={'header-search mobilecode custom-limit '}
                    type="tel"
                    value={customMonthly}
                    placeholder="Custom limit"
                    onIonChange={e => setCustomMonthly(e.detail.value)}>
                  </IonInput>
              }
            </div>
          </div>
          <div className="lower">
            <p onClick={() => save()}  className="next-step unselectable">Save</p>
          </div>

        </div>

        <p className="title-2">Deposit Limit</p>
        <div className='account-details-container games-limit deposit-limit'>
          <div className='step-input-container'>
            <p className='step-input-title'>Limit</p>
            <div className='custom-block'>
              <IonSelect lines="none"
              onIonChange={e => checkDeposit(e.detail.value)}
              value={depositLimit}
              placeholder="Not Specified"
              className={'header-search custom-input ' + (depositLimit !== undefined && depositLimit === '' ? "wrong" : '')}
              interface="popover">
                {limits.map(el => {
                  return(
                  <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                })}
                <IonSelectOption className='hide' value={"custom"}>Custom Limit</IonSelectOption>
              </IonSelect>
              {
                depositLimit === 'custom' &&
                  <IonInput
                    className={'header-search mobilecode custom-limit '}
                    type="tel"
                    value={customDeposit}
                    placeholder="Custom limit"
                    onIonChange={e => setCustomDeposit(e.detail.value)}>
                  </IonInput>
              }
            </div>
          </div>
          <div className="lower">
            <p className="next-step unselectable">Save</p>
          </div>

        </div>

        <p className="title-2">Withdraw Limit</p>
        <div className='account-details-container games-limit withdraw-limit'>
          <div className='step-input-container'>
            <p className='step-input-title'>Limit</p>
            <div className='custom-block'>
              <IonSelect lines="none"
              onIonChange={e => checkWithdraw(e.detail.value)}
              value={withdrawLimit}
              placeholder="Not Specified"
              className={'header-search custom-input ' + (withdrawLimit !== undefined && withdrawLimit === '' ? "wrong" : '')}
              interface="popover">
                {limits.map(el => {
                  return(
                  <IonSelectOption lines="none" key={el} value={el}>{el}</IonSelectOption>)
                })}
                <IonSelectOption className='hide' value={"custom"}>Custom Limit</IonSelectOption>
              </IonSelect>
              {
                withdrawLimit === 'custom' &&
                  <IonInput
                    className={'header-search mobilecode custom-limit '}
                    type="tel"
                    value={customWithdraw}
                    placeholder="Custom limit"
                    onIonChange={e => setCustomWithdraw(e.detail.value)}>
                  </IonInput>
              }
            </div>
          </div>
          <div className="lower">
            <p className="next-step unselectable">Save</p>
          </div>

        </div>

      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={"Your data has been successfully changed."}
        color='primary'
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color='primary'
        duration={10000}
      />
      </div>
    );
};

export default GamesLimit;
