import React from 'react';
import { IonItem,IonLabel } from '@ionic/react';
import { useHistory } from "react-router-dom";
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import '../../../components/css/Register.css';
import url from '../../../axios.js';
import axios from 'axios';
import closeIcon from '../../../images/icons/close.svg';

function CloseAccount(props){
  let history = useHistory();
  function closeAccount(){
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/close-user',
            headers: {
                 'Authorization': `Bearer ${props.data.token}`,
             },
          })
          .then(res => {
            if (res.data.status === 1) {
              props.clearState();
              history.push('/home');
              props.setShowLoading(false);
            }
          })
  }
    return (
      <div className="deposit-money">
        <div className="shade">
          <p className="top-shade">
            <img alt='close account' src={closeIcon} />
            <span>Account</span>
          </p>
          <p className="bottom-shade">Account Closure</p>
        </div>
        <p className="title">
          <span>Account Closure</span>
        </p>
        <div className='account-details-container closure'>
          <p>Please be aware, after you deactivate your account: </p>
          <p>You will not be able to log in or open a new account.</p>
          <p>Pending bets will be settled as normal. </p>
          <p>You will not be able to withdraw money. </p>
          <p>If you decide to come back to Bingo.bet you need to contact customer service. </p>
          <p className="alert-text">Make sure you’re happy with your decision before you confirm your account closure. This action cannot be undone.</p>
        </div>
        <IonItem
          button
          onClick={() => closeAccount()}
          className="register-btn closure-btn">
          <IonLabel>Confirm Closure</IonLabel>
        </IonItem>
      </div>
    );
};

export default CloseAccount;
