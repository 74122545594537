import ReactApexChart from 'react-apexcharts';
import React, {useState, useEffect} from "react";

export default function ApexChart(props) {
  const [series, setSeries] = useState([{name: "", data: []}])
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (props.topGames.length > 0) {
      let gamesNames = [];
      let gamesCount = [];
      props.topGames.forEach((item) => {
        gamesNames.push(item.game_name);
        gamesCount.push(item.cnt)
      });
      let currentOptions = {
        chart: {
          height: 400,
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        colors: ['#F44336', '#E91E63', '#9C27B0','#F44336', '#E91E63', '#9C27B0','#F44336', '#E91E63', '#9C27B0','#F44336'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: gamesNames,
          labels: {
            style: {
              colors: ['#F44336', '#E91E63', '#9C27B0','#F44336', '#E91E63', '#9C27B0','#F44336', '#E91E63', '#9C27B0','#F44336'],
              fontSize: '12px'
            }
          }
        }
      };
      setOptions(currentOptions);
      setSeries([{name: "Total played", data: gamesCount}]);
    }

  }, [props.topGames])

          return (
            <div className='chart-bars'>
              {series[0].data.length > 0 && <ReactApexChart options={options} series={series} type="bar" height={350} />}
            </div>
          );
        }
