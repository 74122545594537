import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardBody,
  CardFooter
} from "shards-react";
import axios from 'axios';
import ApexChart from "./BarChart.js"
import url from '../axios.js';
import moment from 'moment';
import Chart from "../utils/Chart.js";
var chart;
var depositWithdraw;
class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deposit: 50,
      withdrawal: 50,
      bet: 50,
      wins: 50,
      topGames: [],
      value: 'months',
      value2: 'months',
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      },
      depositWithdrawValue: 'months',
    }
    this.canvasRef = React.createRef();
    this.canvasRef2 = React.createRef();
  };
  changePeriod2 = (period) => {
    axios({
          method: 'post',
          url: url + "/api/history-games",
          headers: {
               'Authorization': `Bearer ${this.props.data.token}`,
           },
          data: {
            date_from: moment().subtract(period === 'days'? 7 : 1, period).unix(),
            date_to: moment(new Date()).unix()
          }
        })
        .then(res => {
          if (res.data.data.history.length !== 0) {

            let bets = res.data.data.history.filter(i => i.operation === 'Bet').length / res.data.data.history.length * 100;
            let wins = res.data.data.history.filter(i => i.operation === 'Win').length / res.data.data.history.length * 100;
            console.log(bets, wins);
            this.setState({
                bets: bets.toFixed(2),
                wins: wins.toFixed(2)
            });
            this.updateConfigByMutating(depositWithdraw, this.state.bets, this.state.wins);
          };
        })
  }
  changePeriod = (period) => {
    axios({
          method: 'post',
          url: url + "/api/history-transactions",
          headers: {
               'Authorization': `Bearer ${this.props.data.token}`,
           },
          data: {
            date_from: moment().subtract(period === 'days'? 7 : 1, period).unix(),
            date_to: moment(new Date()).unix()
          }
        })
        .then(res => {
          let deposit = res.data.data.history.filter(i => i.operation === 'deposit').length / res.data.data.history.length * 100;
          let withdrawal = res.data.data.history.filter(i => i.operation === 'withdrawal').length / res.data.data.history.length * 100;
          if (res.data.data.history.length !== 0) {
            // console.log(res.data.data.history);
            this.setState({
                deposit: deposit.toFixed(2),
                withdrawal: withdrawal.toFixed(2)
            });
            this.updateConfigByMutating(chart, this.state.deposit, this.state.withdrawal)
          };

        })
  }
  updateConfigByMutating = (chart, val1, val2) => {
    chart.data.datasets = [
      {
        hoverBorderColor: "#ffffff",
        data: [val1, val2],
        backgroundColor: [
          "#fe9c26",
          "#F25D31",
        ]
      }
    ];
    chart.update();
  }
  getData = () => {
    this.props.setShowLoading(true);
    axios({
          method: 'post',
          url: url + "/api/top-games",
          headers: {
               'Authorization': `Bearer ${this.props.data.token}`,
           },
          data: {
            date_from: moment().subtract(7, 'days').unix(),
            date_to: moment(new Date()).unix()
          }
        })
        .then(res => {
            this.setState({
                topGames: res.data.data.topGames,
            });
          }
        )
    axios({
          method: 'post',
          url: url + "/api/history-transactions",
          headers: {
               'Authorization': `Bearer ${this.props.data.token}`,
           },
          data: {
            date_from: moment().subtract(1, 'months').unix(),
            date_to: moment(new Date()).unix()
          }
        })
        .then(res => {
          this.props.setShowLoading(false);
          let deposit = res.data.data.history.filter(i => i.operation === 'deposit').length / res.data.data.history.length * 100;
          let withdrawal = res.data.data.history.filter(i => i.operation === 'withdrawal').length / res.data.data.history.length * 100;
          if (res.data.data.history.length !== 0) {
            this.setState({
                deposit: deposit.toFixed(2),
                withdrawal: withdrawal.toFixed(2)
            });
          };
          const chartConfig = {
            type: "pie",
            data: {
              datasets: [
                {
                  hoverBorderColor: "#ffffff",
                  data: [this.state.deposit, this.state.withdrawal],
                  backgroundColor: [
                    "#fe9c26",
                    "#F25D31",
                  ]
                }
              ],
              labels: ["Deposit, %", "Withdrawal, %"]
            },
            options: this.state.options
          };
          chart = new Chart(this.canvasRef.current, chartConfig);
        });
        axios({
              method: 'post',
              url: url + "/api/history-games",
              headers: {
                   'Authorization': `Bearer ${this.props.data.token}`,
               },
              data: {
                date_from: moment().subtract(1, 'months').unix(),
                date_to: moment(new Date()).unix()
              }
            })
            .then(res => {
              if (res.data.data.history.length !== 0) {
                this.setState({
                    bets: res.data.data.history.filter(i => i.operation === 'Bet').length / res.data.data.history.length * 100,
                    wins: res.data.data.history.filter(i => i.operation === 'Win').length / res.data.data.history.length * 100
                });
                const chartConfig = {
                  type: "pie",
                  data: {
                    datasets: [
                      {
                        hoverBorderColor: "#ffffff",
                        data: [this.state.bets.toFixed(2), this.state.wins.toFixed(2)],
                        backgroundColor: [
                          "#fe9c26",
                          "#F25D31",
                        ]
                      }
                    ],
                    labels: ["Bets, %", "Wins, %"]
                  },
                  options: this.state.options
                };
                depositWithdraw = new Chart(this.canvasRef2.current, chartConfig);
              };

            })
  }
  componentDidMount() {
      if (this.props.data.token !== null) {
        this.getData();
      }
  }

  render() {
    // const { title } = this.props;
    return (
      <>
      <div className="pies-container">
        <Card small className="h-100">
          <CardBody className="d-flex py-0">
            <canvas
              height="220"
              ref={this.canvasRef}
              className="blog-users-by-device m-auto"
            />
          </CardBody>
          <CardFooter className="border-top">
            <Row>
              <Col>
                <FormSelect
                  size="sm"
                  value={this.state.value}
                  style={{ maxWidth: "130px" }}
                  onChange={(e) => {this.setState({value: e.target.value}); this.changePeriod(e.target.value)}}
                >
                  <option value="week">Last Week</option>
                  <option value="months">Last Month</option>
                  <option value="years">Last Year</option>
                </FormSelect>
              </Col>
              <Col className="text-right view-report">
                {/* eslint-disable-next-line */}
                <div className="button-to">
                <Link className="user-menu-link" to={'/user/transactions'}>Go to history</Link>
                </div>

              </Col>
            </Row>

          </CardFooter>
        </Card>
        <Card small className="h-100">
          <CardBody className="d-flex py-0">
            <canvas
              height="220"
              ref={this.canvasRef2}
              className="blog-users-by-device m-auto"
            />
          </CardBody>
          <CardFooter className="border-top">
            <Row>
              <Col>
                <FormSelect
                  size="sm"
                  value={this.state.value2}
                  style={{ maxWidth: "130px" }}
                  onChange={(e) => {this.setState({value2: e.target.value}); this.changePeriod2(e.target.value)}}
                >
                  <option value="week">Last Week</option>
                  <option value="months">Last Month</option>
                  <option value="years">Last Year</option>
                </FormSelect>
              </Col>
              <Col className="text-right view-report">
                {/* eslint-disable-next-line */}
                <div className="button-to">
                <Link className="user-menu-link" to={'/user/casino-and-games'}>Go to history</Link>
                </div>

              </Col>
            </Row>

          </CardFooter>
        </Card>

      </div>
      <ApexChart topGames={this.state.topGames}/>
      </>
    );
  }
}


UsersByDevice.defaultProps = {
  title: "Statistics",
};

export default UsersByDevice;
