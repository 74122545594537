import React, {useState,useEffect}  from "react";
import { useLocation, useHistory } from "react-router-dom";
import './css/Favorites.css';
import './css/Main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { heartOutline, heart ,star } from 'ionicons/icons';
import { IonIcon, IonToggle,IonToast } from '@ionic/react';
import axios from 'axios';
import url from '../axios.js'
import  GameModal  from './GameModal.js';

function Favorites(props){
  const [html, setHtml] = useState('');
  const [changeCurrencyMess, setChangeCurrencyMess] = useState(false);
  var settings = {
      dots: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      swipe: false,
      touchMove: false,
      draggable: false,
      slidesToShow: 5,
      centerMode: false,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            centerMode: false,
            swipe: true,
            arrows: true,
            touchMove: true,
            draggable: true,
            dots: true
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            centerMode: false,
            swipe: true,
            arrows: true,
            touchMove: true,
            draggable: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            centerMode: false,
            swipe: true,
            arrows: true,
            touchMove: true,
            draggable: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            centerMode: false,
            swipe: true,
            arrows: true,
            touchMove: true,
            draggable: true,
            dots: false
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            swipe: true,
            arrows: true,
            touchMove: true,
            draggable: true,
            dots: false
          }
        },
      ]
    };
  let location = useLocation();
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [gamesToRender, setGamesToRender] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState('');
  const [favID, setFavID] = useState([]);
  // var tempFavs = [];
  // var tempRecommended = [];
  let history = useHistory();
  useEffect(() => {
    setToggleSwitch(false);
  },[location.pathname])
  useEffect(() => {
    let tempFavs = [];
    props.data.favs.map(el => {
      if (el.favorite === 1) {
        tempFavs.push(el.id);
        setFavID(tempFavs);
      };
      return null
    });
    tempFavs = [];
    setGamesToRender(props.data.recommended);
    setToggleSwitch(false);
 },[props.data.favs, props.data.recommended, props.games]);
  function toggle() {
    setToggleSwitch(!toggleSwitch);
      if (toggleSwitch === true) {
        setGamesToRender(props.data.recommended);
      } if (toggleSwitch === false) {
          setGamesToRender(props.data.favs);
          setToggleSwitch(true);
      }
    }

    function showGame(e){
      props.openGame(+e.target.getAttribute("data-id"));
      props.setShowLoading(true);
      axios({
              method: 'post',
              url: url + '/api/run-game',
              headers: {
                   'Authorization': `Bearer ${props.token}`,
               },
              data: {
                id: +e.target.getAttribute("data-id"),
                lang: props.data.applang,
                currency: props.data.userData.currency_id
              }
            })
            .then(res => {
              if (res.data.data.linkType === 'URL') {
                setGameUrl(res.data.data.url);
              }
              if (res.data.data.linkType === 'HTML') {
                setGameUrl('');
                setHtml(res.data.data.html);
              }
              // props.setShowLoading(false);
              setIsOpen(true);
            })
            .catch((error) => {
              if (error.response.data.error.includes('Currency not supported') || error.response.data.error.includes('Not supported currency')  ) {
                props.setShowLoading(false);
                setChangeCurrencyMess(true);
              }

             })

      // history.push(`/game/${+e.target.getAttribute("data-id")}`);
    }
    function showDemoGame(e) {
      props.openGame(+e.target.getAttribute("data-id"));
      props.setShowLoading(true);
      axios({
              method: 'post',
              url: url + '/api/run-demo-game',
              headers: {
                   'Authorization': `Bearer ${props.token}`,
               },
              data: {
                id: +e.target.getAttribute("data-id"),
                lang: props.data.applang,
                currency: props.data.userData.currency_id
              }
            })
            .then(res => {
              if (res.data.data.linkType === 'URL') {
                setGameUrl(res.data.data.url);
              }
              if (res.data.data.linkType === 'HTML') {
                setGameUrl('');
                setHtml(res.data.data.html);
              }
              // props.setShowLoading(false);
              setIsOpen(true);
            })
    }
  function pickedFav(id, val) {
    let tempFavs = favID;
    if (val === 1) {
      tempFavs.push(id);
    } else {
      tempFavs = tempFavs.filter(item => item !== id);
    }
    setFavID(tempFavs.reverse());
    tempFavs = [];
    axios({
        method: 'post',
        url: url + '/api/set-favorite',
        headers: {
             'Authorization': `Bearer ${props.token}`,
         },
        data: {
          id: id,
          favorite: val
        }
      })
      .then(res => {
        props.getGames(true, props.token);
      })
  }
  return (
    <div className='favorites-container'>
    {toggleSwitch === false &&
      <>
        <p className="type">Recommended Games</p>
        <p className="type-description">Discover and enjoy some of our most popular games</p>
      </>
    }
    {toggleSwitch === true &&
      <>
        <p className="type fvs">Your Favourites</p>
        <p className="type-description">Here are your favourite games ready to enjoy</p>
      </>
    }
      <div className='selector'>
        <IonToggle
          checked={toggleSwitch}
          value={toggleSwitch}
          onIonChange={() => toggle()}/>
      </div>
      {gamesToRender.length === 0 && toggleSwitch === true && <p className="nofavs">You don't have any favourites yet! Click the heart icon to add/remove your favourite game to this list. </p>}
    {gamesToRender.length !== 0 &&
      <Slider {...settings}>

    {gamesToRender.map((el,i)=> {
      return (
        <div key={i} className='game'>

          <div
            className={'main-box'}
            >
            {el.recommended === 1 && <p className="star"><IonIcon size="large" icon={star}></IonIcon></p>}
            <div className={' blurred'} style={ {backgroundImage: `url(${el.banner_url})`}}></div>

            <div className='topleft'>
              <img src={`${process.env.PUBLIC_URL}/assets/provider-logos/${el.provider}.png`} alt='Provider logo' className="provider-logo"></img>
            </div>
            <img className='game-preview' alt={el.name} src={`${el.banner_url}`}></img>
            <div data-fav={el.favorite} data-game-id={el.id} className={'game-hovered'}>
              <div className='btns'>
                {props.token !== null && <div data-id={el.id} onClick={(e) => showGame(e)} className={'play-game'}>Play</div>}
              {el.hasDemo === "1" &&  <div data-id={el.id} onClick={(e) => showDemoGame(e)} className={'play-game demo'}>Demo</div>}
              </div>
            </div>
          </div>
          <div data-fav={el.favorite} data-game-id={el.id} className="bottom">
            <p>{el.name}</p>
            {
              props.token !== null &&
              <IonIcon
                onClick={
                  (e) => pickedFav(el.id, el.favorite === 1 ? 0 : 1)
                  }
                size="large"
                icon={favID.indexOf(+el.id) !== -1 ? heart : heartOutline }>
              </IonIcon>}
          </div>

        </div>
      )
    })}

    </Slider>}
    {toggleSwitch === true && gamesToRender.length > 9 &&
      <div onClick={() => history.push('/favorites')} className="go-to-favs">To favorites</div>
    }
    <IonToast
      isOpen={changeCurrencyMess}
      onDidDismiss={() => setChangeCurrencyMess(false)}
      message={'Your favorite currency is not supported by this game. Please change your favorite currency.'}
      color='orange'
      duration={5000}
      buttons={[
        {
          side: 'end',
          text: 'Change currency',
          handler: () => history.push('/user/personal-details')
        }
      ]}
    />
    <GameModal setShowLoading={props.setShowLoading} html={html} setGameUrl={setGameUrl} setHtml={setHtml}  token={props.token} getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Favorites;
