import React, {useState,useEffect} from 'react';
import { IonLabel,IonItem} from '@ionic/react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import balanceIcon from '../../../images/icons/balance.svg';

export default function Balance(props) {
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [bonusTotal, setBonusTotal] = useState(0);
  // const [userCurrency, setUserCurrency] = useState(props.data.userData.currency_id);
  useEffect(() => {
    let balance = 0;
    let bonus = 0;
    if (props.data.balance.length > 0) {
      props.data.balance.forEach((item, i) => {
        balance += +item.ob_priv;
        bonus += +item.ob_priv_b;
      });
      setBalanceTotal(balance.toFixed(2));
      setBonusTotal(bonus.toFixed(2))
    }
  },[props.data.balance]);

  function pickCurrency(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  return(
    <div className="transfer-money">
      <div className="shade">
        <p className="top-shade">
          <img alt='balance' src={balanceIcon} className="balance-icon" />
          <span>Balance</span>
        </p>
        <p className="bottom-shade"></p>
      </div>
      <p className="title">
        <span>Balance</span>
      </p>

      {
          props.data.balance.map((el, i) => {
            return (
                <p key={i} className="deposit">Deposit in {getCurrency(el.currency_id)} <span>{el.ob}{pickCurrency(el.currency_id)}</span></p>
            )
          })
      }
      <p className="deposit">Total balance <span>{balanceTotal}{getCurrency(props.data.userData.currency_id)}</span></p>
      <p className="deposit">Total bonuses  <span>{bonusTotal}{getCurrency(props.data.userData.currency_id)}</span></p>
      <p className="deposit">Cashback  <span>0{getCurrency(props.data.userData.currency_id)}</span></p>
      <div className='account-details-container closure'>
      </div>
    </div>
  )
}
