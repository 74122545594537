import React, {useState} from 'react';
import {  IonInput, IonToast } from '@ionic/react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import '../../../components/css/Register.css';
import url from '../../../axios.js';
import axios from 'axios';
import contactIcon from '../../../images/icons/profile.svg';

function ContactDetails(props){
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [addressLine1, setAddressLine1] = useState(props.data.userData.address_line);
  const [addressLine2, setAddressLine2] = useState(props.data.userData.address_line2);
  const [city, setCity] = useState(props.data.userData.city);
  const [postcode, setPostcode] = useState(props.data.userData.postcode);
  const [stateRegion, setStateRegion] = useState(props.data.userData.state);
  const [mobile, setMobile] = useState(props.data.userData.phone);
  const [mobileCode, setMobileCode] = useState(props.data.userData.country_code);
    function save() {
      if (addressLine1 === undefined || addressLine1 === "") {
         setAddressLine1('');
     // } if (addressLine2 === undefined || addressLine2 === "") {
     //    setAddressLine2('');
     } if (city === undefined || city === "") {
        setCity('');
     } if (postcode === undefined || postcode === "") {
        setPostcode('');
     } if (stateRegion === undefined || stateRegion === "") {
        setStateRegion('');
     } if (mobile === undefined || mobile === "") {
        setMobile('');
     } if (mobileCode === undefined || mobileCode === "") {
        setMobileCode('');
     } if (addressLine1 !== undefined &&
          addressLine1 !== '' &&
          // addressLine2 !== undefined &&
          // addressLine2 !== '' &&
          city !== undefined &&
          city !== '' &&
          postcode !== undefined &&
          postcode !== '' &&
          stateRegion !== undefined &&
          stateRegion !== '' &&
          mobile !== undefined &&
          mobile !== '' &&
          mobileCode !== undefined &&
          mobileCode !== '') {
            props.setShowLoading(true);
            var data = {
               address_line: addressLine1,

               city: city,
               postcode: postcode,
               state: stateRegion,
               mobile: mobile,
               country_code: mobileCode,
             };
             addressLine2 === "" ? console.log("No adress line 2") : data.address_line2 = addressLine2;
             axios({
                     method: 'post',
                     url: url + '/api/update-profile',
                     headers: {
                          'Authorization': `Bearer ${props.data.token}`,
                      },
                     data: data,
                   })
                   .then(res => {
                     if (res.data.status === 1) {
                       console.log(res.data.data.user);
                       props.updateUser(props.data.token)
                       setShowToast1(true);
                       props.setShowLoading(false);
                     }
                   })
                   .catch(error => {
                     /*422*/
                     setShowToast2Text(error.response.data)
                     setShowToast2(true);
                     props.setShowLoading(false);
                    })

          }
    }
    return (
      <div className="deposit-money">
        <div className="shade">
          <p className="top-shade">
            <img alt='contact details' src={contactIcon} />
            <span>Account</span>
          </p>
          <p className="bottom-shade">Contact Details</p>
        </div>
        <p className="title">
          <span>Account Details</span>
        </p>
        <p className="title-2">Contact Details</p>
        <div className='account-details-container'>
        <div className='step-input-container'>
          <p className='step-input-title'>Address Line</p>
          <IonInput
            className={'header-search ' + (addressLine1 !== undefined && addressLine1 === '' ? "wrong" : '')}
            type="text"
            value={addressLine1}
            placeholder="Address Line"
            onIonChange={e => setAddressLine1(e.detail.value)}></IonInput>
        </div>
        <div className='step-input-container'>
          <p className='step-input-title'>Address Line 2</p>
          <IonInput
            className={'header-search '}
            type="text"
            value={addressLine2}
            placeholder="Address Line 2"
            onIonChange={e => setAddressLine2(e.detail.value)}></IonInput>
        </div>
        <div className='step-input-container'>
          <p className='step-input-title'>City</p>
          <IonInput
            className={'header-search ' + (city !== undefined && city === '' ? "wrong" : '')}
            type="text"
            value={city}
            placeholder="City"
            onIonChange={e => setCity(e.detail.value)}>
          </IonInput>
        </div>
        <div className='step-input-container'>
          <p className='step-input-title'>Postcode</p>
          <IonInput
            className={'header-search ' + (postcode !== undefined && postcode === '' ? "wrong" : '')}
            type="text"
            value={postcode}
            placeholder="Postcode"
            onIonChange={e => setPostcode(e.detail.value)}>
          </IonInput>
        </div>
        <div className='step-input-container'>
          <p className='step-input-title'>State/Region</p>
          <IonInput
            className={'header-search ' + (stateRegion !== undefined && stateRegion === '' ? "wrong" : '')}
            type="text"
            value={stateRegion}
            placeholder="State/Region"
            onIonChange={e => setStateRegion(e.detail.value)}>
          </IonInput>
        </div>
        <div className='step-input-container'>
          <p className='step-input-title'>Mobile</p>
          <IonInput
            className={'header-search mobilecode ' + (mobileCode !== undefined && mobileCode === '' ? "wrong" : '')}
            type="tel"
            value={mobileCode}
            placeholder="Country code"
            onIonChange={e => setMobileCode(e.detail.value)}>
          </IonInput>
          <IonInput
            className={'header-search mobile ' + (mobile !== undefined && mobile === '' ? "wrong" : '')}
            type="tel"
            value={mobile}
            placeholder="Mobile"
            onIonChange={e => setMobile(e.detail.value)}>
          </IonInput>
        </div>
        <div className="lower">
          <p onClick={() => save()}  className="next-step unselectable">Save</p>
        </div>

      </div>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={"Your data has been successfully changed."}
        color='primary'
        duration={10000}
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={showToast2Text}
        color='primary'
        duration={10000}
      />

      </div>
    );
};

export default ContactDetails;
