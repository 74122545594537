import Menu from './components/Menu';
import Page from './pages/Page';
import React from 'react';
import { IonApp,IonModal, IonRouterOutlet, IonSplitPane, IonLoading} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Link } from "react-router-dom";
// import {  Route } from 'react-router-dom';
import axios from 'axios';
import './app-main.css';
import './components/css/Media.css';
// import Chat from './components/Chat.js';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import Main from './pages/Main.js';
import Documents from './pages/Documents.js';
import Bonus from './pages/Bonus.js';
import Games from './pages/Games.js';
import Register from './pages/Register.js';
import Providers from './pages/Providers.js';
import Premium from './pages/Premium.js';
import Favorites from './pages/Favorites.js';
import User from './pages/user/User.js';
import Tournaments from './pages/Tournaments.js';
import Jackpots from './pages/Jackpots.js';
import TournamentDetails from './pages/TournamentDetails.js';
import url from './axios.js';

class Casino extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loginModal: false,
      restoreModal: false,
      registerModal: false,
      applang: "GB",
      messageList: [],
      jackPotValue: 0,
      winners: [0],
      providersList: [],
      wasHere: false,
      jackPotCount: 0,
      currencies:[],
      tournaments: [],
      favsArray:[],
      showMainLoading: true,
      userData:[],
      cacheControl: new Date().getDate(),
      favs: [],
      recommended:[],
      toggle: false,
      logins:[],
      openGameId: null,
      uuid: '',
      bonuses:[],
      timeleft:[],
      games: [],
      warningBottom: true,
      tags: [],
      banners: {desktop:[], mobile: []},
      advs: {desktop:[], mobile: []},
      countries: [],
      mode: false,
      balance: [],
      defaultAvatar: 'https://sun1-84.userapi.com/towoR7THO0npdci8NZrl0zqn2-P5TgyzE07_Fg/xzOi-Ig9qWs.jpg',
      token: null,
      tournamentIndex: 0,
      tournamentSingle: [],
    };
  };
  setTournamentIndex = (index) => {
    this.setState({tournamentIndex: index});
    this.setState({tournamentSingle: this.state.tournaments[index]});
  }
  setMainLogin = (boo) => {
    if (boo === true) {
      document.getElementsByTagName('body')[0].classList.add('hide-chat')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('hide-chat')
    }
    this.setState({loginModal: boo});
  }
  setFavsArray = (prop) => {
    this.setState({favsArray: prop});
  }
  toggleMode = (prop) => {
    // console.log(prop);
    this.setState({mode: prop});
    if (prop === true) {
      document.getElementsByTagName('body')[0].classList.add('dark');
    }
    if (prop === false) {
      document.getElementsByTagName('body')[0].classList.remove('dark');
    }

  }
  changeAppLang = (value) => {
    this.setState({applang: value});
  }
  openGame = (id) => {
    this.setState({openGameId: id});
  }
  clearState = () => {
    this.setState((state) => {
        return {
          userData: [],
          token: null
        }
      });
  }
  setToken = (token) => {
    /*установка токена при логине*/
    this.setShowLoading(true);
    this.getGames(true, token);
    this.getInfo(token);
  }
  setUuid = (id) => {
    this.setState({uuid: id});
  }
  getCountries = () => {
    axios({
          method: 'post',
          url: url + '/api/permitted-countries',
        })
        .then(res => {
          // console.log(res);
          this.setState({countries: res.data});
        });
      }
  getGames = (boo, token) => {
    // console.log('get-games');
    if (boo === true) {
      axios({
            method: 'post',
            url: url + '/api/list-games-auth',
            headers: {
                 'Authorization': `Bearer ${token}`,
             },
             data: {
               platform: window.innerWidth <= 800 ? 'mobile' : 'desktop',
             }
          })
          .then(res => {
            this.setState({
              providersList : Array.from(new Set(
                res.data.data.listGames.map(el => {
                  return el.provider;
                })
              )),
              games: res.data.data.listGames,
              favs: res.data.data.listGames.filter(it => it.favorite === 1),
              recommended: res.data.data.listGames.filter(it => it.recommended === 1)
            });
            this.setShowLoading(false);
          });
    } if (boo === false) {
      axios({
            method: 'post',
            url: url + '/api/list-games-new',
            data: {
              platform: window.innerWidth <= 800 ? 'mobile' : 'desktop',
            }

          })
          .then(res => {
            this.setState({
              providersList : Array.from(new Set(
                res.data.data.listGames.map(el => {
                  return el.provider;
                })
              )),
              games: res.data.data.listGames,
              recommended: res.data.data.listGames.filter(it => it.recommended === 1)
            });
            this.setShowLoading(false);
          });
    }

      }
  getInfo = (token) => {
    axios({
          method: 'post',
          url: url + '/api/user',
          headers: {
               'Authorization': `Bearer ${token}`,
           }
        })
        .then(res => {
          this.setState({userData: res.data.data.player});
          this.setState({balance: res.data.data.balance});
          this.setState({token: token});
          localStorage.setItem ("token", token);
          this.setShowLoading(false);
        })
        .catch(error=>{
          if (error.message === 'Request failed with status code 401') {
            localStorage.removeItem ("token");
            window.location.reload();
          }
        })
        axios({
          method: 'post',
          url: url + '/api/multiple-logins',
          headers: {
               'Authorization': `Bearer ${token}`,
           },
        })
        .then(res => {
          this.setState({logins: res.data.data.session.reverse()});
        })
  };
  getLatestWinners = () => {
    axios({
      method: 'post',
      url: url + '/api/get-winners'
    })
    .then(res => {
      this.setState(
        {
          winners: res.data,
        }
      );
    })
  }
  getJackpotValue = () => {
    axios({
      method: 'post',
      url: url + '/api/get-jackpot-total'
    })
    .then(res => {
      this.setState(
        {
          jackPotValue: res.data[0].value,
          jackPotCount: res.data[0].count
        }
      );
    })
  }
  getBanners = () => {
    axios({
      method: 'post',
      url: url + '/api/get-banners-new'
    })
    .then(res => {
      this.setState(
        {
          banners: {
            desktop: res.data.desktop,
            mobile: res.data.mobile
          },
          advs: {
            desktop: res.data.desktopLow,
            mobile: res.data.mobileLow
          }
        }
      );
    })
  }
  getBonuses = () => {
    axios({
      method: 'post',
      url: url + '/api/get-doc-bonuses',
      data: {
        lang: 'GB'
      }
    })
    .then(res => {
      // console.log(JSON.parse(Object.values(res.data.data)));

      this.setState(
        {
          bonuses: res.data.data.map((el,i) => {
            // console.log(JSON.parse(el));
            return {
              name: JSON.parse(el)[0].name,
              img: JSON.parse(el)[3].contents,
              short: JSON.parse(el)[1].contents,
              shortImg: JSON.parse(el)[2].contents,
              content: JSON.parse(el)[4].contents,
            };
          }),
        }
      );
    })
  }
  getCookie = (name) =>  {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
  UNSAFE_componentWillMount() {
    this.checkIfWasHere();
  }
  getCurrencies = () => {
    axios({
      method: 'post',
      url: url + '/api/get-currencies'
    })
    .then(res => {
      this.setState({currencies: res.data});
    })
  }
  checkCache = () => {
    if (+localStorage.getItem ("cacheControl") === null) {
      localStorage.setItem ("cacheControl", this.state.cacheControl);
      window.location.reload(true);
    } if (+localStorage.getItem ("cacheControl") !== this.state.cacheControl) {
      localStorage.setItem ("cacheControl", this.state.cacheControl);
      window.location.reload(true);
    } if (+localStorage.getItem ("cacheControl") === this.state.cacheControl) {
    }

  }
  getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }
  getTournaments = () => {
    axios({
      method: 'post',
      url: url + '/api/get-tournaments'
    })
    .then(res => {
      this.setState({tournaments: res.data.data});
    })
  }
  componentDidMount() {
    this.getTournaments();
    this.checkCache();
    this.getCurrencies();
    this.getBanners();
    this.setShowLoading(true);
    this.getLatestWinners();
    this.getBonuses();
    this.getJackpotValue();
    let token = localStorage.getItem ("token");
      this.getCountries();
      axios({
        method: 'post',
        url: url + '/api/list-tags'
      })
      .then(res => {
        this.setState({tags: res.data.data.listTags});
      })
      if (token !== null || this.state.token !== null) {
        this.getInfo(token);
        this.getGames(true, token);
      }else {
        this.getGames(false, null);
      }

  }
  setShowLoading = (boo) => {
    this.setState({showMainLoading: boo});
  };
  checkIfWasHere = () => {
    if (localStorage.getItem ("wasHere") === null) {
      this.setState((state) => {
          return {
            wasHere: true
          }
        });
        document.getElementsByTagName('body')[0].classList.toggle('hide-chat');
    }  if(localStorage.getItem ("wasHere") === 'true') {
      this.setState((state) => {
          return {
            wasHere: false
          }
        })
    }

  }
  fromDocs = (boo) => {
    if(localStorage.getItem ("wasHere") !== 'true') {
      this.setState({warningBottom:boo});
    }
  }
  render() {
    const NotFound = () => {
      return (
        <h3>404 - Not found</h3>
      );
    };
    return (
      <IonApp className={this.state.mode === true ? 'dark' : ''}>
        <IonReactRouter>
          <Menu setShowLoading={this.setShowLoading}
                getInfo={this.getInfo}
                setMainLogin={this.setMainLogin}
                clearState={this.clearState}
                data={this.state}
                token={this.state.token}
                openGame={this.openGame}
                setToken={this.setToken}
                setUuid={this.setUuid}
                changeAppLang={this.changeAppLang}
                 />
          <IonSplitPane disabled= 'true' contentId="main">
          {this.state.warningBottom === true &&
            <IonModal mode={"md"} isOpen={this.state.wasHere} backdropDismiss={false} cssClass='was-here my-custom-class'>
            <div className="was-here-container modal-container">
              <p>By entering the site, you accept our <Link to={'/documents/data-protection'}>Data Protection Policy</Link>, <Link to={'/documents/cookie-policy'}>Cookie Policy</Link> and <Link to={'/documents/disclaimer'}>Disclaimer</Link> and confirm you are at least 18 years of age.</p>
              <div className="login-btn" onClick={() => {localStorage.setItem('wasHere', 'true'); this.checkIfWasHere();document.getElementsByTagName('body')[0].classList.toggle('hide-chat')}}>Accept</div>
            </div>

          </IonModal>}
            {/*<Chat/>*/}
            { this.state.token === null && <div className={'sticky ' + (window.location.pathname.includes('demo') ? 'hide-mobile' : '')}>
                <div className={'login'} onClick={() => this.setMainLogin(true)} >
                Login
                </div>
                <div className={'sign-up'}>
                <Link to={'/register'}>
                Sign Up
                </Link>
                </div>
                <div className={'help-sticky'} >
                  <Link to={'/documents/help'}>
                  Help
                  </Link>
                </div>
              </div>
            }
            { this.state.token !== null && <div className={'sticky'}>
            <div className={'help-sticky'} >
              <Link to={'/documents/help'}>
              Help
              </Link>
            </div>
              </div>
            }
            <IonRouterOutlet  mode= "md" animated="false" animation={() => this.animation} id="main">
            <Route path="/home" render={() =>
              <Main
                setMainLogin={this.setMainLogin}
                timeleft={this.state.timeleft}
                tournaments={this.state.tournaments}
                setFavsArray={this.setFavsArray}
                changeAppLang={this.changeAppLang}
                winners = {this.state.winners}
                banners = {this.state.banners}
                advs={this.state.advs}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                favs={this.state.favs}
                getInfo={this.getInfo}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                data={this.state} />}
              />
              <Route path="/page/:name"  render={() =>  <Page data={this.state} />   }/>
              <Route path="/tournaments" render={() =>
                <Tournaments
                  setTournamentIndex={this.setTournamentIndex}
                  setMainLogin={this.setMainLogin}
                  timeleft={this.state.timeleft}
                  tournaments={this.state.tournaments}
                  setFavsArray={this.setFavsArray}
                  changeAppLang={this.changeAppLang}
                  jackPotValue = {this.state.jackPotValue.toLocaleString()}
                  banners = {this.state.banners}
                  mode = {this.state.mode}
                  toggleMode = {this.toggleMode}
                  getInfo={this.getInfo}
                  openGame={this.openGame}
                  data={this.state}
                  clearState={this.clearState}
                  setShowLoading={this.setShowLoading}
                  setToken={this.setToken}
                  setUuid={this.setUuid}
                  page={'Bonus'}
                />}/>
                <Route path="/tournament-details" render={() =>
                  <TournamentDetails
                    tournamentIndex={this.tournamentIndex}
                    setMainLogin={this.setMainLogin}
                    timeleft={this.state.timeleft}
                    getGames={this.getGames}
                    tournaments={this.state.tournamentSingle}
                    setFavsArray={this.setFavsArray}
                    changeAppLang={this.changeAppLang}
                    jackPotValue = {this.state.jackPotValue.toLocaleString()}
                    banners = {this.state.banners}
                    mode = {this.state.mode}
                    toggleMode = {this.toggleMode}
                    getInfo={this.getInfo}
                    openGame={this.openGame}
                    games={this.state.games}
                    data={this.state}
                    clearState={this.clearState}
                    setShowLoading={this.setShowLoading}
                    setToken={this.setToken}
                    setUuid={this.setUuid}
                    page={'Bonus'}
                    token={this.state.token}
                  />}/>
              <Route path="/bonus" render={() =>
                <Bonus
                setMainLogin={this.setMainLogin}
                  setFavsArray={this.setFavsArray}
                  changeAppLang={this.changeAppLang}
                  jackPotValue = {this.state.jackPotValue.toLocaleString()}
                  banners = {this.state.banners}
                  mode = {this.state.mode}
                  toggleMode = {this.toggleMode}
                  getInfo={this.getInfo}
                  openGame={this.openGame}
                  data={this.state}
                  clearState={this.clearState}
                  setShowLoading={this.setShowLoading}
                  setToken={this.setToken}
                  setUuid={this.setUuid}
                  page={'Bonus'}
                />}/>
              <Route exact path="/user/:page" render={() =>
              <User
              setMainLogin={this.setMainLogin}
                changeAppLang={this.changeAppLang}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                clearState={this.clearState}
                logins={this.state.logins}
                updateUser={this.getInfo}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                data={this.state} />}
              />
              <Route path="/providers" render={() =>
                <Providers
                setMainLogin={this.setMainLogin}
                setFavsArray={this.setFavsArray}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                favs={this.favs}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                getInfo={this.getInfo}
                data={this.state} />
                }
              />
              <Route path="/premium" render={() =>
                <Premium
                setMainLogin={this.setMainLogin}
                setFavsArray={this.setFavsArray}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                getInfo={this.getInfo}
                token={this.state.token}
                favs={this.favs}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                data={this.state} />
                }
              />
              <Route exact path="/games/:page" render={() =>
              <Games
              setMainLogin={this.setMainLogin}
                setFavsArray={this.setFavsArray}
                changeAppLang={this.changeAppLang}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                favs={this.favs}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                getInfo={this.getInfo}
                data={this.state} />}
              />
              <Route path="/favorites" render={() =>
              <Favorites
              setMainLogin={this.setMainLogin}
                favsArray={this.state.favsArray}
                changeAppLang={this.changeAppLang}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                favs={this.favs}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                getInfo={this.getInfo}
                data={this.state} />}
              />
              <Route path="/jackpots" render={() =>
              <Jackpots
                setMainLogin={this.setMainLogin}
                favsArray={this.state.favsArray}
                changeAppLang={this.changeAppLang}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                openGame={this.openGame}
                favs={this.favs}
                getGames={this.getGames}
                games={this.state.games}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                getInfo={this.getInfo}
                data={this.state} />}
              />
              <Route path="/documents/:page" render={() =>
                <Documents
                setMainLogin={this.setMainLogin}
                changeAppLang={this.changeAppLang}
                fromDocs={this.fromDocs}
                data={this.state}
                openGame={this.openGame}
                setShowLoading={this.setShowLoading}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                />} />
              <Route exact path="/user" render={() =>
                <User
                changeAppLang={this.changeAppLang}
                openGame={this.openGame}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                logins={this.state.logins}
                updateUser={this.getInfo}
                setShowLoading={this.setShowLoading}
                clearState={this.clearState}
                setToken={this.setToken}
                setUuid={this.setUuid}
                data={this.state} />}
                />
              <Route exact path="/register/" render={() =>
                <Register
                setMainLogin={this.setMainLogin}
                changeAppLang={this.changeAppLang}
                banners = {this.state.banners}
                jackPotValue = {this.state.jackPotValue.toLocaleString()}
                openGame={this.openGame}
                mode = {this.state.mode}
                toggleMode = {this.toggleMode}
                token = {this.token}
                clearState={this.clearState}
                setShowLoading={this.setShowLoading}
                setToken={this.setToken}
                setUuid={this.setUuid}
                data={this.state} />}
                />
                <Redirect from="/" to="/home"  exact/>

              <Route component={NotFound} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
        <IonLoading
          isOpen={this.state.showMainLoading}
          // isOpen={true}
          onDidDismiss={() => this.setShowLoading(false)}
          spinner={'dots'}
          duration={3000}
          cssClass={'loading-hidden'}
        />
      </IonApp>
    );
  }

};

export default Casino;
