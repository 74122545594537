import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom'
import  Banner  from '../components/Banner.js';
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import '../components/css/Bonuses.css';
import MobileMenu from '../components/MobileMenu';


function Bonus(props) {
    function maximize(i) {
      document.getElementsByClassName('bonus-content')[i].classList.add('opened');
      document.getElementsByClassName('open-close')[i].classList.add('hide-this');
      document.getElementsByClassName('minimiz')[i].classList.add('visible');
    }
    function minimize(i) {
      document.getElementsByClassName('bonus-content')[i].classList.remove('opened');
      document.getElementsByClassName('open-close')[i].classList.remove('hide-this');
      document.getElementsByClassName('minimiz')[i].classList.remove('visible');
    }
    return (
      <IonPage>
        <IonContent overflow-scroll="true" className='main-page bss' class="has-header">
          {/**/}
          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data}/>
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            toggle = {props.toggle}
            getInfo={props.getInfo}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            setUuid={props.setUuid}
            page={'Профиль'}
            data={props.data}
            />
            <div className="banner-container main" >
              <Banner link={'/bonus'} banners = {props.banners} mode = {props.mode}/>
              <div className={'banner-info '} >
                <div className='jackpot-roll'></div>
                <div className='jackpot-title'>
                  <div className={'jackpot-top '} >
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/premium'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="bonuses-container">
              <div className="bg-container pic-123"></div>

              {props.data.bonuses.map((el, i) => {
                // console.log(el);
                return (
                  <div key={i} className="bonus">
                    <div dangerouslySetInnerHTML={{__html: el.img}}></div>
                    <h3>{el.name}</h3>
                    <div className={'bonus-content'} dangerouslySetInnerHTML={{__html: el.content}}></div>
                    <div onClick={() => maximize(i)} className={"play-now open-close"}>Read more</div>
                    <div onClick={() => minimize(i)} className={"play-now minimiz"}>Hide</div>
                  </div>
                )
              })}
              </div>
            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default Bonus;
