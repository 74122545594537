import React, {useState} from "react";
import url from '../axios.js';
import axios from 'axios';
import {  IonToast } from "@ionic/react";
import './css/Latest.css';
import { useLocation, useHistory } from "react-router-dom";
import  GameModal  from '../components/GameModal.js';


function LatestWinners(props){
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [gameUrl, setGameUrl] = useState('');
  const [changeCurrencyMess, setChangeCurrencyMess] = useState(false);
  const [html, setHtml] = useState('');
  function showGame(e){
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-game',
            headers: {
                 'Authorization': `Bearer ${props.token}`,
             },
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            if (res.data.data.linkType === 'URL') {
              setGameUrl(res.data.data.url);
            }
            if (res.data.data.linkType === 'HTML') {
              setGameUrl('');
              setHtml(res.data.data.html);
            }
            // props.setShowLoading(false);
            setIsOpen(true);
          })
          .catch((error) => {
            if (error.response.data.error.includes('Currency not supported') || error.response.data.error.includes('Not supported currency')  ) {
              props.setShowLoading(false);
              setChangeCurrencyMess(true);
            }

           })
    // history.push(`/game/${+e.target.getAttribute("data-id")}`);
  }
  function showDemoGame(e) {
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-demo-game',
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            setGameUrl(res.data.data.url_demo);
            // props.setShowLoading(false);
            setIsOpen(true);
          })
  }
  return (
    <div className='latest-container'>
      <div className='winners-container'>
        <h3>Latest Winners</h3>
        <div className="winners">
        {
          props.winners.map((el,i)=> {
            let bg = '';
            props.games.forEach((item, i) => {
              if (item.id === el.game_id) {
                bg = item.banner_url;
              }
            });

            return(
              <div key={i} className='winner'>
                <div style={ {backgroundImage: `url(${bg})`}} className='img'></div>
                <div className='info'>
                  <p className='who'>{el.name} just won</p>
                  <p className='how-much'>${el.value}</p>
                  <p className='game-title'>{el.game_name}</p>
                </div>
                {props.token !== null && <div data-id={el.game_id} onClick={(e) => showGame(e)} className='play-now'>Play Now</div>}
                {props.token === null && <div data-id={el.game_id} onClick={(e) => showDemoGame(e)} className='play-now'>Play Now</div>}

              </div>
            )
          })
        }

        </div>
      </div>
      <p className='line'></p>
      <div className='jackpot-container'>
        <h3>Jackpot Total</h3>
        <h4>{props.jackPotValue} $</h4>
      </div>
      <IonToast
        isOpen={changeCurrencyMess}
        onDidDismiss={() => setChangeCurrencyMess(false)}
        message={'Your favorite currency is not supported by this game. Please change your favorite currency.'}
        color='orange'
        duration={5000}
        buttons={[
          {
            side: 'end',
            text: 'Change currency',
            handler: () => history.push('/user/personal-details')
          }
        ]}
      />
      <GameModal setShowLoading={props.setShowLoading} html={html} setGameUrl={setGameUrl} setHtml={setHtml} token={props.token} getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default LatestWinners;
