import React from 'react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';

function Terms(props){

    return (
      <div className="funds-security">
        <div className="shade">
          <p className="top-shade">Help & Info</p>
          <p className="bottom-shade">Terms & Conditions</p>
        </div>

        <div className='funds-security'>
          <div className='funds-security-container'>
            <p className="title terms">Terms & Conditions</p>

            <p>LETTER A INVESTMENT LIMITED (UK) is not currently FCA authorised as it relies on "groups exclusion" contained in Alternative Investment Fund Managers Directive which has been implemented in the United Kingdom through the provisions of Schedule 8 (Persons Excluded from Regulated Activity of Managing an AIF) of Financial Services and Markets Act 2000 (Regulated Activities) Order 2001.</p>
            <p>Nothing on this site is intended to or shall be deemed to constitute financial, investment or other professional advice or an offer to sell, or a solicitation of an offer to buy, in respect of any financial product or investment advice or other services. You should consult your financial or other professional advisor if you require any financial, investment or other professional advice.</p>
            <p>Please note that LETTER A INVESTMENT LIMITED (UK) reserves the right to change, amend or delete the content and availability of all or any part of the pages of this site at its sole discretion and without prior notice.</p>

          </div>
        </div>
      </div>
    );
};

export default Terms;
