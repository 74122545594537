import React, { useState, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import Banner from "../components/Banner.js";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import { useLocation } from "react-router-dom";
import Favorites from "../components/Favorites.js";
import { Link } from "react-router-dom";
import Livecasino from "./games/Livecasino.js";

export default function Providers(props) {
  let location = useLocation();
  const [providersShow] = useState(true);
  const [isCurrent] = useState("");
  const [games, setGames] = useState([]);
  const [toShow] = useState([]);
  let tempArr = [];

  useEffect(() => {
    setGames([]);
  }, [location.pathname]);
  let filterGamesProvider = (e, el, i) => {
    whereAreWeProvider(i);
    let arr = [];
    toShow.length === 0 ? (arr = props.games) : (arr = toShow);
    arr.map(element => {
      if (element.provider === el) {
        tempArr.push(element);
      }
      return null;
    });
    setGames(tempArr);
      let yOffset = document.getElementById('here').offsetTop;
      document.getElementById('providers-page').scrollToPoint(0, yOffset, 500);
    // setProvidersShow(false);
  };
  let whereAreWeProvider = index => {
    // setIsCurrentProvider(index);
  };
  let getCountIds = (target, name) => {
    const result = {};
    target.forEach(item =>
      result[item.provider]
        ? result[item.provider]++
        : (result[item.provider] = 1)
    );
    return Object.keys(result).map(item => {
      if (item === name) {
        return result[item];
      }
      return null;
    });
  };
  return (
    <IonPage className={"provs"}>
      <IonContent id={"providers-page"} overflow-scroll="true" class="has-header">
        <MobileMenu
          toggleMode={props.toggleMode}
          changeAppLang={props.changeAppLang}
          setShowLoading={props.setShowLoading}
          setUuid={props.setUuid}
          setToken={props.setToken}
          data={props.data}
        />
        <Header
          setMainLogin={props.setMainLogin}
          changeAppLang={props.changeAppLang}
          toggle={props.toggle}
          getInfo={props.getInfo}
          openGame={props.openGame}
          toggleMode={props.toggleMode}
          clearState={props.clearState}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
          setUuid={props.setUuid}
          page={"Профиль"}
          data={props.data}
        />
        <div className="banner-container">
          <Banner link={"/bonus"} banners={props.banners} mode={props.mode} />
          <div className={"banner-info"}>
            <div className="jackpot-roll live-pic" />
            <div className="jackpot-title">
              <div className="jackpot-top">
                <div className="jackpot-text">
                  <p>JACKPOT {props.jackPotValue} $ </p>
                  <Link to={"/jackpots"} className="jackpot-playnow" />
                </div>
                <div className="jackpot-links">
                  <Link className="tourney" to={"/tournaments"}>
                    <div className={"bx"} />
                    <p>Tournaments</p>
                  </Link>
                  <Link className="premium" to={"/premium"}>
                    <div className={"bx"} />
                    <p>Premium</p>
                  </Link>
                  <Link className="providers" to={"/providers"}>
                    <div className={"bx"} />
                    <p>providers</p>
                  </Link>
                  <Link className="bonuses" to={"/bonus"}>
                    <div className={"bx"} />
                    <p>bonuses</p>
                  </Link>
                </div>
              </div>
              <div className="jackpot-bottom" />
            </div>
          </div>
        </div>
        <Favorites
          data={props.data}
          setFavsArray={props.setFavsArray}
          getGames={props.getGames}
          token={props.data.token}
          getInfo={props.getInfo}
          openGame={props.openGame}
          games={props.games}
          setShowLoading={props.setShowLoading}
        />
        <div
          id={"providers"}
          className={
            "providers-container " + (providersShow === true ? "shown" : "")
          }
        >
          {providersShow && (
            <div className={"providers-cards"}>
              {Array.from(
                new Set(
                  props.games.map(el => {
                    return el.provider;
                  })
                )
              ).map((el, i) => {
                return (
                  <div
                    data-i={i}
                    className={
                      "provider-card " +
                      (isCurrent.toString() === i.toString()
                        ? "is-current"
                        : "")
                    }
                    onClick={e => filterGamesProvider(e, el, i)}
                    key={i}
                  >
                    <p className="total-games">
                      {getCountIds(props.games, el)} games
                    </p>
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/provider-logos/${el}.png`}
                      className="provider-logo"
                      alt={el}
                    />
                    <p className="provider-name">{el}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div id={"here"} />
        <Livecasino
          getInfo={props.getInfo}
          data={props.data}
          openGame={props.openGame}
          userData={props.data.userData}
          favs={props.favs}
          getGames={props.getGames}
          setShowLoading={props.setShowLoading}
          token={props.data.token}
          games={games}
        />
        <Footer providers={props.data.providersList}/>
      </IonContent>
    </IonPage>
  );
}
