import { IonContent, IonPage, IonIcon,IonToast } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import  Banner  from '../components/Banner.js';
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import MobileMenu from '../components/MobileMenu';
import { Card, CardBody } from "shards-react";
import { heartOutline, heart, star } from 'ionicons/icons';
import url from '../axios.js';
import axios from 'axios';
import moment from 'moment';
import  GameModal  from '../components/GameModal.js';
import { Redirect} from 'react-router-dom';
require('moment-countdown');

function TournamentDetails(props) {
  let location = useLocation();
  // let history = useHistory();
  /*eslint-disable*/
  const history = useHistory();
  const [changeCurrencyMess, setChangeCurrencyMess] = useState(false);
  const [games, setGames] = useState(props.games);
  const [showGames, setShowGames] = useState(false);
  const [tournaments, setTournaments] = useState(props.tournaments);
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState('');
  const [time, setTime] = useState([]);
  const [timer, setTimer] = useState([]);
  const [html, setHtml] = useState('');
  const [currentGames, setCurrentGames] = useState([]);
  useEffect(() => {
     setGames(props.games);
  },[props.games]);
  useEffect(() => {
    // if (tournaments.length === 0) {
      setTournaments(props.tournaments);
    // }
  },[props.tournaments]);
  // useEffect(() => {
  //   tickThis()
  // },[tournaments]);
  useEffect(() => {
    if (!location.pathname.includes('/tournament-details')) {
      clearInterval(timer);
      setShowGames(false)
    }
    if (location.pathname.includes('/tournament-details')) {
      tickThis();
    }
  },[location.pathname]);
  function tickThis(){
    if (tournaments.length !== 0) {
      setTimer(setInterval(() => {
        let temp = [];
          //время до начала турнира
          if (moment.unix(tournaments.start) > moment()) {
            temp.push(moment(moment.unix(tournaments.start).format('YYYY-MM-DD').toString()).countdown())
          }
          //время до конца турнира
          if (moment.unix(tournaments.start) < moment() && moment.unix(tournaments.end) > moment()) {
            temp.push(moment(moment.unix(tournaments.end).format('YYYY-MM-DD').toString()).countdown());
          }
        setTime(temp);
      }, 1000))
    }
  }
  function showGame(e){
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-game',
            headers: {
                 'Authorization': `Bearer ${props.token}`,
             },
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            if (res.data.data.linkType === 'URL') {
              setGameUrl(res.data.data.url);
            }
            if (res.data.data.linkType === 'HTML') {
              setGameUrl('');
              setHtml(res.data.data.html);
            }
            // props.setShowLoading(false);
            setIsOpen(true);
          })
          .catch((error) => {
            if (error.response.data.error.includes('Currency not supported') || error.response.data.error.includes('Not supported currency')  ) {
              props.setShowLoading(false);
              setChangeCurrencyMess(true);
            }

           })
    // history.push(`/game/${+e.target.getAttribute("data-id")}`);
  }
  function showDemoGame(e) {
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-demo-game',
            headers: {
                 'Authorization': `Bearer ${props.token}`,
             },
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            if (res.data.data.linkType === 'URL') {
              setGameUrl(res.data.data.url);
            }
            if (res.data.data.linkType === 'HTML') {
              setGameUrl('');
              setHtml(res.data.data.html);
            }
            // props.setShowLoading(false);
            setIsOpen(true);
          })
  }
  function pickedFav(e) {
    let gameID = e.target.parentNode.getAttribute("data-game-id");
    let index = favorites.indexOf(+e.target.parentNode.getAttribute("data-game-id"));
    if (index === -1) {
      let arr = favorites;
      arr.push(+e.target.parentNode.getAttribute("data-game-id"));
      setFavorites(arr);
      sendFavs(gameID, 1);
      e.target.icon = heart;
    } if(index !== -1) {
      let arr = favorites;
      arr.splice(index, 1);
      sendFavs(gameID, 0)
      setFavorites(arr);
      e.target.icon = heartOutline;
    }
  }
  function sendFavs(id, fav) {
    axios({
        method: 'post',
        url: url + '/api/set-favorite',
        headers: {
             'Authorization': `Bearer ${props.token}`,
         },
        data: {
          id: id,
          favorite: +fav
        }
      })
      .then(res => {
        console.log(res);
        props.getGames(true, props.token);
      })
  }
    const [favorites, setFavorites] = useState([]);
    function getCurrencyValue(currency_id) {
      if (currency_id === 840) {
        return '$'
      }
      if (currency_id === 978) {
        return '€'
      }
      if (currency_id === 643) {
        return '₽'
      }
      if (currency_id === 826) {
        return '£'
      }
    }
    return (
      <IonPage>
      {props.data.token === null &&
        <Redirect to="/tournaments"/>
      }
        <IonContent overflow-scroll="true" className='main-page trnmnts-details'>
          {/**/}
          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            getInfo={props.getInfo}
            toggle = {props.toggle}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            setUuid={props.setUuid}
            data={props.data}
            />
            <div className="banner-container main" >
              <Banner link={'/bonus'} banners = {props.banners} mode = {props.mode}/>
              <div className={'banner-info '} >
                <div className='jackpot-roll'></div>
                <div className='jackpot-title'>
                  <div className={'jackpot-top '} >
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/premium'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {tournaments &&
              <div className="bonuses-container tour">
              <div className="bg-container pic-123"></div>
              <div className="single-tournament">
                <h3>{tournaments.name.replace('<br/>',' ')}</h3>
                <h3 className='prize-fund'>Prize Fund - {tournaments.prizeFund} {getCurrencyValue(tournaments.currency.id)}</h3>
                <p className='tournir-date-start'>Tournament starts <b>{moment.unix(tournaments.start).format('DD.MM.YYYY')}</b></p>
                <p className='tournir-date-end'>Tournament ends <b>{moment.unix(tournaments.end).format('DD.MM.YYYY')}</b></p>
                {!moment(time[0]).format('DD').includes('Invalid') && <p className='tournir-date-timer'>{moment(time[0]).format('DD')} days {moment(time[0]).format('hh:mm:ss')}</p>}
                {moment(time[0]).format('DD').includes('Invalid') && <p className='tournir-date-timer'>Tournament has been ended</p>}
                <Card className="card-table">
                  <CardBody className="p-0 pb-3">
                     <table className="table mb-0">
                      <thead className="card-header-main">
                        <tr>
                          <th scope="col" className="border-0 top-places">
                            Top places
                          </th>
                          <th scope="col" className="border-0 top-places">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {tournaments.places.map((el,i)=> {
                        return (
                          <tr key={i}>
                            <td className={
                              ('left-td ') +
                              (i === 0 ? 'first-place' : '' ) +
                              (i === 1 ? 'second-place' : '' ) +
                              (i === 2 ? 'third-place' : '' )
                              }>
                                <div className={
                                  ('no-img ') +
                                  (i === 0 ? 'first-place' : '' ) +
                                  (i === 1 ? 'second-place' : '' ) +
                                  (i === 2 ? 'third-place' : '' )
                                  }
                                >
                                </div>
                                <div className="place">{++i}</div>
                            </td>
                            <td className={
                              (i === 1 ? 'first-place' : '' ) +
                              (i === 2 ? 'second-place' : '' ) +
                              (i === 3 ? 'third-place' : '' )
                              }>{el}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>

                  </CardBody>
                </Card>
                {showGames === false && <div onClick={() => setShowGames(true)} className='read-more'>Show games</div>}
                {showGames === true && <div onClick={() => setShowGames(false)} className='read-more'>Hide games</div>}
                {showGames === true && <div className="video-slots-container">
                  {
                    tournaments.games.map((el,i) => {
                    return props.games.map((item, index) => {
                        if (item.id === el) {
                            return(
                                <div key={i} className={'game '}>
                                  <div
                                    className={'main-box'}
                                    >
                                    {item.recommended === 1 && <p className="star"><IonIcon size="large" icon={star}></IonIcon></p>}
                                    <div className={' blurred'} style={ {backgroundImage: `url(${item.banner_url})`}}></div>
                                    <div className='topleft'>
                                      <img alt={'provider'} src={  `${process.env.PUBLIC_URL}/assets/provider-logos/${item.provider}.png`} className="provider-logo"></img>
                                    </div>
                                    <img className='game-preview' alt={el.name} src={`${item.banner_url}`}></img>

                                    <div data-fav={item.favorite} data-game-id={item.id} className={'game-hovered'}>
                                      <div className='btns'>
                                        {props.token !== null && <div data-id={item.id} onClick={(e) => showGame(e)} className={'play-game'}>Play</div>}
                                      {el.hasDemo === "1" &&  <div data-id={item.id} onClick={(e) => showDemoGame(e)} className={'play-game demo'}>Demo</div>}
                                      </div>
                                    </div>
                                  </div>
                                  <div data-fav={item.favorite} data-game-id={item.id} className="bottom">
                                    <p>{item.name}</p>
                                    {props.token !== null && <IonIcon onClick={(e) => pickedFav(e)} size="large" icon={favorites.indexOf(+item.id) !== -1 ? heart : heartOutline }></IonIcon>}
                                  </div>

                                </div>
                            )
                        };
                        return null
                      });
                    })
                  }
                </div>}
                <div dangerouslySetInnerHTML={{__html: JSON.parse(tournaments.description)[1].contents}} className="tournament-detail-cms">
                </div>
              </div>
            </div>}
            <IonToast
              isOpen={changeCurrencyMess}
              onDidDismiss={() => setChangeCurrencyMess(false)}
              message={'Your favorite currency is not supported by this game. Please change your favorite currency.'}
              color='primary'
              duration={5000}
              buttons={[
                {
                  side: 'end',
                  text: 'Change currency',
                  handler: () => history.push('/user/personal-details')
                }
              ]}
            />
            <GameModal setShowLoading={props.setShowLoading} setGameUrl={setGameUrl} setHtml={setHtml} html={html} token={props.token} getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default TournamentDetails;
