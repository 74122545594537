import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
export default function Banner(props) {
  return (
    <>
    {
      window.innerWidth > 800 &&
        <Carousel
          interval={3000}
          autoPlay={true}
          showThumbs={false}
          emulateTouch={false}
          stopOnHover={false}
          swipeable={true}
          infiniteLoop={true}
          showArrows={false}>
          {
            props.banners.desktop.map((el,i) => {
              return(<Link to={el.link} key={i} dangerouslySetInnerHTML={{__html: el.json}}></Link>)
            })
          }
       </Carousel>
    }
    {
      window.innerWidth <= 800 &&
        <Carousel
          interval={3000}
          autoPlay={true}
          showThumbs={false}
          emulateTouch={false}
          stopOnHover={false}
          swipeable={false} infiniteLoop={true} showArrows={false}>
          {
            props.banners.mobile.map((el,i) => {
              return(<Link to={el.link} key={i} dangerouslySetInnerHTML={{__html: el.json}}></Link>)
            })
          }
       </Carousel>
    }
   </>
  )
}
