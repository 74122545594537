import React from 'react';
import './css/UserMenu.css';
import { HashLink as Link } from 'react-router-hash-link';
import deposit from '../images/icons/deposit2.svg';
import {ReactComponent as Withdraw} from '../images/icons/withdraw.svg';
import {ReactComponent as Exchange} from '../images/icons/exchange.svg';
import balance from '../images/icons/balance.svg';
import loan from '../images/icons/loan.svg';
import about from '../images/icons/about.svg';
import cookie from '../images/icons/cookie.svg';
import privacy from '../images/icons/privacy.svg';
import kyc from '../images/icons/kyc.svg';
import contributions from '../images/icons/contributions.svg';
import disclaimer from '../images/icons/disclaimer.svg';
import responsible from '../images/icons/policy.svg';
import dataPolicy from '../images/icons/data-policy.svg';
import fundsSecurity from '../images/icons/funds-security.svg';
import transactions from '../images/icons/transaction2.svg';
import {ReactComponent as Casino} from '../images/icons/casino.svg';
import {ReactComponent as User} from '../images/icons/user.svg';
import statistics from '../images/icons/trend.svg';
import {ReactComponent as Contact} from '../images/icons/profile.svg';
import {ReactComponent as Limit} from '../images/icons/limit.svg';
import {ReactComponent as Multiple} from '../images/icons/team.svg';
import {ReactComponent as Verify} from '../images/icons/check.svg';
import {ReactComponent as Close} from '../images/icons/close.svg';
import {ReactComponent as Help} from '../images/icons/question.svg';
import {ReactComponent as Terms} from '../images/icons/terms.svg';

function MenuUser(props) {
  return (
        <div id='user-menu'>
          <div className="user-menu-container">
          <p className="user-menu-title">Statistics</p>
          <p className="with-link">
            <img alt='statistics' src={statistics} />
            <Link className="user-menu-link" to={'/user'}>Overall Statistics</Link>
          </p>
          </div>
          <div className="user-menu-container">
            <p className="user-menu-title">Balance</p>
            <p className="with-link">
              <img alt='deposit' src={deposit} />
              <Link className="user-menu-link" to={'/user/deposit-money'}>Deposit Money</Link>
            </p>
            <p className="with-link">
              <Exchange/>
              <Link className="user-menu-link" to={'/user/transfer-money'}>Exchange Money</Link>
            </p>
            <p className="with-link">
              <Withdraw/>
              <Link className="user-menu-link" to={'/user/withdraw-money'}>Withdraw Money</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={balance} />
              <Link className="user-menu-link" to={'/user/balance'}>Balance</Link>
            </p>
            <p className="with-link">
              <img alt='fundsSecurity' src={loan} />
              <Link className="user-menu-link" to={'/user/loan'}>Loan request</Link>
            </p>
            <p className="with-link">
              <img alt='fundsSecurity' src={fundsSecurity} />
              <Link className="user-menu-link" to={'/user/funds-security'}>Funds Security</Link>
            </p>
          </div>
          <div className="user-menu-container">
            <p className="user-menu-title">History</p>
            <p className="with-link">
              <img alt='transactions' src={transactions} />
              <Link className="user-menu-link" to={'/user/transactions'}>Transactions</Link>
            </p>
            <p className="with-link">
              <Casino/>
              <Link className="user-menu-link" to={'/user/casino-and-games'}>Casino & Games</Link>
            </p>

          </div>
          <div className="user-menu-container">
            <p className="user-menu-title">Account Details</p>
            <p className="with-link">
              <User/>
              <Link className="user-menu-link" to={'/user/personal-details'}>Personal Details</Link>
            </p>
            <p className="with-link">
              <Contact/>
              <Link className="user-menu-link" to={'/user/contact-details'}>Contact Details</Link>
            </p>
            <p className="with-link">
              <Limit/>
              <Link className="user-menu-link" to={'/user/games-limit'}>Games Limit</Link>
            </p>
            <p className="with-link">
              <Multiple/>
              <Link className="user-menu-link" to={'/user/multiple-logins'}>Multiple Logins</Link>
            </p>
            <p className="with-link">
              <Verify/>
              <Link className="user-menu-link" to={'/user/verify-account'}>Verify Account</Link>
            </p>
            <p className="with-link">
              <Close/>
              <Link className="user-menu-link" to={'/user/close-account'}>Close Account</Link>
            </p>

          </div>
          <div className="user-menu-container">
            <p className="user-menu-title">Help & Info</p>
            <p className="with-link">
              <Help/>
              <Link className="user-menu-link" to={'/documents/help'}>Help</Link>
            </p>
            <p className="with-link">
              <Terms/>
              <Link className="user-menu-link" to={'/documents/terms'}>Terms & Conditions</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={about} />
              <Link className="user-menu-link" to={'/documents/about'}>About Us</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={cookie} />
              <Link className="user-menu-link" to={'/documents/cookie-policy'}>Cookie policy</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={privacy} />
              <Link className="user-menu-link" to={'/documents/policy'}>Privacy policy</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={kyc} />
              <Link className="user-menu-link" to={'/documents/kyc-policy'}>KYC Policy</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={contributions} />
              <Link className="user-menu-link" to={'/documents/disclaimer'}>Disclaimer</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={disclaimer} />
              <Link className="user-menu-link" to={'/documents/contributions'}>Contributions</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={responsible} />
              <Link className="user-menu-link" to={'/documents/responsible-policy'}>Responsible gambling policy</Link>
            </p>
            <p className="with-link">
              <img alt='balance' src={dataPolicy} />
              <Link className="user-menu-link" to={'/documents/data-protection'}>Data protection policy</Link>
            </p>


          </div>
          {/*<div className="header-menu-top">
            <Link to={'/video-slots'} className={'videoSlots hvr-float-shadow'}smooth>
              <div className={'box'}></div>
              <p>Video Slots</p>
            </Link>
            <Link smooth className={'tableGames hvr-float-shadow'} to={'/table-games'}>
              <div className={'box'}></div>
              <p>
                Table Games
              </p>
            </Link>
            <Link smooth className={'liveCasino hvr-float-shadow'} to={'/live-casino'}>
              <div className={'box'}></div>
              <p>
                Live Casino
              </p>
            </Link>
            <Link smooth to={'/bets'} className={"bets-red hvr-float-shadow"}>
              <div className={'box'}></div>
              <p>
                Bets
              </p>
            </Link>
          </div>*/}
        </div>
        )

};

export default MenuUser;
