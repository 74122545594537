import React from 'react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import fundsSecurityIcon from '../../../images/icons/funds-security.svg';

function FundsSecurity(props){

    return (
      <div className="funds-security">
        <div className="shade">
          <p className="top-shade">
            <img alt='funds security' src={fundsSecurityIcon} />
            <span>Funds</span>
          </p>
          <p className="bottom-shade">Security</p>
        </div>

        <div className='funds-security'>
          <div className='funds-security-container'>
            <p className="title">Funds Security</p>
            <p>Your online security is very important to us. We provide the same levels of security when transferring your funds as banks do and you can be reassured that we have complied with all online regulations and implemented the best in online security.</p>
            <p className="title">Encryption</p>
            <p> After logging in, all information sent to and from the site is encrypted using 128-bit Secure Socket Layer (SSL) technology. The SSL certificate used is issued and verified by Trustwave. This means that any information going through our site cannot be intercepted by a third party. </p>
            <p className="title">PCI DSS Level 1 Compliance </p>
            <p>This payment system has been certified as PCI-DSS Level 1 Compliant. This PCI-DSS level is the highest level of certification available and means that we follow all of the security requirements from Visa and MasterCard.<br></br> For some transactions, we may need to ask you for documentation that proves your identity, address, account details or the integrity of your credit card. We do so to ensure maximum security of transactions and to comply with our license requirements. We will contact you directly via email if this is needed.</p>
            <div className='mastercard'></div>
            <div className='visa'></div>
          </div>
        </div>
      </div>
    );
};

export default FundsSecurity;
