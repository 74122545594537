import { IonContent, IonPage } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useLocation,useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import  Banner  from '../components/Banner.js';
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import MobileMenu from '../components/MobileMenu';
import moment from 'moment';
require('moment-countdown');


function Tournaments(props) {
  let location = useLocation();
  let history = useHistory();
  const [tournaments, setTournaments] = useState([]);
  const [time, setTime] = useState([]);
  const [timer2, setTimer2] = useState([]);
  useEffect(() => {
    if (tournaments.length === 0) {
      setTournaments(props.tournaments);
    }
  },[props.tournaments, tournaments.length]);
  /*eslint-disable*/
  useEffect(() => {
    tickThis()
  },[tournaments]);
  useEffect(() => {
    if (location.pathname !== '/tournaments') {
      clearInterval(timer2)
    }
    if (location.pathname === '/tournaments') {
      tickThis();
    }
  },[location.pathname]);
  function tickThis(){
    if (tournaments.length !== 0) {
      setTimer2(setInterval(() => {
        let temp = [];
        tournaments.forEach((el, i) => {
          //время до начала турнира
          if (moment.unix(el.start) > moment()) {
            temp.push(moment(moment.unix(el.start).format('YYYY-MM-DD').toString()).countdown())
          }
          //время до конца турнира
          if (moment.unix(el.start) < moment() && moment.unix(el.end) > moment()) {
            temp.push(moment(moment.unix(el.end).format('YYYY-MM-DD').toString()).countdown());
          }
        });
        setTime(temp);
      }, 1000))
    }
  }
  function getCurrencyValue(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
    return (
      <IonPage className='tms'>
        <IonContent overflow-scroll="true" className='main-page' class="has-header">
          {/**/}
          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            getInfo={props.getInfo}
            toggle = {props.toggle}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            setUuid={props.setUuid}
            page={'Профиль'}
            data={props.data}
            />
            <div className="banner-container main" >
              <Banner link={'/bonus'} banners = {props.banners} mode = {props.mode}/>
              <div className={'banner-info '} >
                <div className='jackpot-roll'></div>
                <div className='jackpot-title'>
                  <div className={'jackpot-top '} >
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/premium'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="bonuses-container">
              <div className="bg-container pic-123"></div>

              {props.tournaments.length > 0 && props.tournaments.map((el, i) => {
                // console.log(el.name);
                let days = moment(time[i]).format('DD');
                let timeleft = moment(time[i]).format('hh:mm:ss');
                let string = days.includes('Invalid') ? 'Tournament has been ended': days + ' days ' + timeleft
                return (
                  <div key={i} className="bonus tournament-single">
                    <div className='image'><img alt='tournament' src={el.image}></img></div>
                    <center><h3 dangerouslySetInnerHTML={{ __html: el.name }} /></center>
                    <p className='tournir-date-start'>Tournament starts <b>{moment.unix(el.start).format('DD.MM.YYYY')}</b></p>
                    <p className='tournir-date-end'>Tournament ends <b>{moment.unix(el.end).format('DD.MM.YYYY')}</b></p>
                    <p className='tournir-date-timer'>
                    { string }
                    </p>

                    <h3 className='prize-fund'>Prize Fund - {el.prizeFund}{getCurrencyValue(el.currency.id)}</h3>
                    <div onClick={() => {props.setTournamentIndex(i); history.push(`${'/tournament-details?'+ i}`)}} className='read-more'>Read more</div>
                  </div>
                )
              })}
              </div>
            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default Tournaments;
