import React, { useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import  Banner  from '../components/Banner.js';
import MobileMenu from '../components/MobileMenu';
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import  Favorites  from '../components/Favorites.js';
import { Card, CardBody } from "shards-react";
import { Link } from 'react-router-dom';
import {ReactComponent as Bronze} from '../images/medals/bronze.svg';
import {ReactComponent as New} from '../images/medals/new.svg';
import {ReactComponent as Silver} from '../images/medals/silver.svg';
import {ReactComponent as Gold} from '../images/medals/gold.svg';
import {ReactComponent as Platinum} from '../images/medals/platinum.svg';
import {ReactComponent as Diamond} from '../images/medals/diamond.svg';
import {ReactComponent as Exclusive} from '../images/medals/exclusive.svg';

export default function Premium(props){
  const [tableHead] = useState(['PREMIUM LOYALTY LEVEL', 'NEW','BRONZE','SILVER','GOLD','PLATINUM','DIAMOND', 'EXCLUSIVE']);
  const [rows] = useState([
    ['Time limit and lost money needed to reach the level','—','100$/month','500$/month','3000$/month','10000$/6 months','Exclusive Level by Personal Invitation','Exclusive Level by Personal Invitation'],
    ['Reward money','—','100$','200$','1000$','10000$','50000$','100000$'],
    ['Fast Cash Out Process','✔','✔','✔','✔','EXPRESS!','EXPRESS!','EXPRESS!'],
    ['Birthday Bonus','✔','✔','✔','✔','✔','✔','✔'],
    ['Sunday Bonus','—','✔','✔','✔','✔','✔','✔'],
    ['LIVE Tournaments','—','✔','✔','✔','✔','✔','✔'],
    ['Higher Deposit & Cash Out Limits','—','—','—','—','✔','✔','✔'],
    ['Personalised Offer','—','—','—','—','✔','✔','✔'],
    ['Personal account manager','—','—','—','—','✔','✔','✔'],
    ['Monthly Cash Back','—','—','—','—','✔','✔','✔'],

  ])
  // const [tableLevels, setTableLevels] = useState([ 'Reward money','Fast Cash Out Process','Birthday Bonus', 'Sunday Bonus','LIVE Tournaments','Higher Deposit & Cash Out Limits','Personalised Offer','Personal account manager','Monthly Cash Back'])


  return (
    <IonPage >
      <IonContent overflow-scroll="true" className="prm">
        {/**/}
        <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
        <Header
          setMainLogin={props.setMainLogin}
          changeAppLang={props.changeAppLang}
          getInfo={props.getInfo}
          toggle = {props.toggle}
          openGame={props.openGame}
          toggleMode={props.toggleMode}
          clearState={props.clearState}
          setShowLoading={props.setShowLoading}
          setToken={props.setToken}
          setUuid={props.setUuid}
          page={'Профиль'}
          data={props.data}
          />
          <div className="banner-container">
            <Banner link={'/bonus'} banners={props.banners} mode = {props.mode}/>
            <div className={'banner-info'}>
                <div className='jackpot-roll table-pic'></div>
              <div className='jackpot-title'>
                <div className='jackpot-top'>
                  <div className='jackpot-text'>
                    <p>JACKPOT {props.jackPotValue} $ </p>
                    <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                  </div>
                  <div className='jackpot-links'>
                    <Link className="tourney" to={'/tournaments'}>
                      <div className={'bx'}></div>
                      <p>
                        Tournaments
                      </p>
                    </Link>
                    <Link className="premium" to={'/premium'}>
                      <div className={'bx'}></div>
                      <p>
                        Premium
                      </p>
                    </Link>
                    <Link className="providers" to={'/providers'}>
                      <div className={'bx'}></div>
                      <p>
                        providers
                      </p>
                    </Link>
                    <Link className="bonuses" to={'/bonus'}>
                      <div className={'bx'}></div>
                      <p>
                        bonuses
                      </p>
                    </Link>
                  </div>
                </div>
                <div className='jackpot-bottom'>

                </div>
              </div>
            </div>
          </div>
          <Favorites getInfo={props.getInfo} data={props.data} setShowLoading={props.setShowLoading} setFavsArray={props.setFavsArray} getGames={props.getGames} token={props.data.token} openGame={props.openGame} games={props.games}/>

          <div className="premium-container rltv">
          <h3>Premium loyalty</h3>
            <Card className="card-table">
              <CardBody className="p-0 pb-3">
                 <table className="table mb-0">
                  <thead className="card-header-main">
                    <tr>
                      {
                        tableHead.map((el,i) => {
                          return (
                            <th key={i} scope="col" className="border-0 top-places">

                              {i === 1 &&<New/>}
                              {i === 2 &&<Bronze/>}
                              {i === 3 &&<Silver/>}
                              {i === 4 &&<Gold/>}
                              {i === 5 &&<Platinum/>}
                              {i === 6 &&<Diamond/>}
                              {i === 7 &&<Exclusive/>}
                              {i === 8 &&<Exclusive/>}
                              <p>{el}</p>
                            </th>
                          )
                        })
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {
                      rows.map((el,i) => {
                        return(
                            <tr key={i}>
                            {
                              el.map((element,item) => {
                              return (
                                  <td className={(item === 0 ? 'first-column' : '')} key={item}>
                                    {element}
                                  </td>
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>

              </CardBody>
              <h3>Special Benefits</h3>
              <p className='benefits'>There are many special bonus offers and gifts that are now included in our Loyalty Program for all our players. Below details some important information regarding these new benefits.</p>
              <ul>
                <li>
                Identity must be checked before participating
                </li>
                <li>
                All Loyalty Welcome Package benefits come into effect the day after the upgrade takes place.
                </li>
                <li>
                Platinum, Diamond & Exclusive Welcome Package bonuses are offered over a 3-day period.
                </li>
                <li>
                The Sunday bonus is only issued when a deposit has been made in the previous 14 days.
                </li>
                <li>
                Sunday bonuses are credited to a players account upon login- no deposit required!
                </li>
                <li>
                You are only eligible for 1 bonus a day. Therefore, if you are upgraded over the weekend, the Welcome Package bonus takes priority over any other offer, including our weekly Sunday promotion.
                </li>
                <li>
                The monthly cash back bonus is based on Bingo.bet winnings on all games for one full calendar month, and you can use cashback only in games.
                </li>
                <li>
                The cash back bonus is issued on the 3rd Monday of the following month and covers the previous month’s full calendar.
                </li>
                <li>
                  Contact your Personal Account Manager to claim your monthly cashback bonus and for more details.
                </li>
                <li>
                One bonus per player. We have the right to suspend this bonus if you are in breach of this clause.
                </li>
                <li>
                Your Premium Loyalty Level is based on Bingo.bet winnings on all games for one full calendar month.
                </li>
                <li>
                As your loyalty to us grows, so does your premium level! However, our Diamond and Exclusive tiers are by invitation only.
                </li>
                <li>
                Upon reaching each new (higher) level, you receive a cashback bonus.
                </li>
                <li>
                You can check your status in your account
                </li>
                <li>
                Games contribute differently to the wagering requirements. The list of games and their respective weighting towards the wagering requirement can be found <Link to={'/documents/contributions'}>here</Link>.
                </li>
                <li>
                  Our Website terms and conditions apply in full. These can be found <Link to={'/documents/terms'}>here</Link>. Our standard promotional terms and conditions also apply. These can be found <Link to={'/documents/terms'}>here</Link>.
                </li>
                <li>
                  Bonus funds can't be withdrawn until you have met all of these requirements above. Only cash funds can be withdrawn at any time and at your request.
                </li>
              </ul>
            </Card>
            <div className='bg-container pic-123'></div>
          </div>


          <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
  )
}
