import React, { useState, useEffect }  from "react";
import { IonModal,IonItem,IonLabel } from '@ionic/react';
import axios from 'axios';
import './css/Header.css';
import {  useHistory } from "react-router-dom";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import url from '../axios.js'
import { HashLink as Link } from 'react-router-hash-link';
import {ReactComponent as New} from '../images/medals/new.svg'
import {ReactComponent as Bronze} from '../images/medals/bronze.svg';
import {ReactComponent as Silver} from '../images/medals/silver.svg';
import {ReactComponent as Gold} from '../images/medals/gold.svg';
import {ReactComponent as Platinum} from '../images/medals/platinum.svg';
import {ReactComponent as Diamond} from '../images/medals/diamond.svg';
import {ReactComponent as Exclusive} from '../images/medals/exclusive.svg';

function UserActions(props) {
  let history = useHistory();
  const [showPopover, setShowPopover] = useState(false);
  const [text, setText] = useState(props.data.applang);
  function moveToUser() {
    history.push('/user')
  }
  useEffect(() => {
    setText(props.data.applang)
  },[props.data.applang]);
  function logout() {
      props.setShowLoading(true);
    axios({
          method: 'post',
          url: url + '/api/logout',
          headers: {
               'Authorization': `Bearer ${props.data.token}`,
           },
        })
        .then(res => {
          setShowPopover(false);
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
          props.clearState();
          props.setShowLoading(false);
          document.getElementsByTagName('body')[0].classList.remove('hide-chat')
        });
  }
    return (
      <div className={'userActions only-desktop'}>
          {
          props.data.token == null &&
          <div className={'login-register-container unlogged'}>
            <IonItem
              button
              onClick={() => props.setShowLoginModal()}
              className="login-btn">
              <IonLabel>Login</IonLabel>
            </IonItem>
            <IonItem
              button
              routerLink="/register"
              className="register-btn">
              <IonLabel>Sign Up</IonLabel>
            </IonItem>
          </div>
            }
            {
            props.data.token !== null &&
            <div className={'login-register-container logged'}>
              <div onClick={() => moveToUser()} className='userpic'
                style={ props.data.userData.avatar === null ? {backgroundImage: `url(${props.data.defaultAvatar})`} : {backgroundImage: `url(${props.data.userData.avatar})`} }
                >
                {props.data.userData.player_status_id === 1 && <New/>}
                {props.data.userData.player_status_id === 2 && <Bronze/>}
                {props.data.userData.player_status_id === 3 && <Silver/>}
                {props.data.userData.player_status_id === 4 && <Gold/>}
                {props.data.userData.player_status_id === 5 && <Platinum/>}
                {props.data.userData.player_status_id === 6 && <Diamond/>}
                {props.data.userData.player_status_id === 7 && <Exclusive/>}
              </div>
              <Link to={'/user'} className="name">{props.data.userData.first_name + ' ' + props.data.userData.last_name|| 'guest'}</Link>
              <p className="logout logout-btn" onClick={() => {setShowPopover(true);document.getElementsByTagName('body')[0].classList.add('hide-chat')}}>Logout</p>

            </div>
              }
          <div className={'header-search-container lang-select'}>
            <ReactFlagsSelect
              className={'header-search country'}
             searchable={false}
             value={text}
             onSelect={e => {props.changeAppLang(e); setText(e)}}
             defaultCountry={props.data.applang}
             customLabels={{"GB": "English", "ES": "Spanish","IT": "Italian","DE": "German","NL": 'Dutch',"PL": "Polish", "PT": "Portuguese", "RU": "Russian", "TR": "Turkish", "FR": "French", "CN": "Chinese","JP": "Japanese", "AE": "Arabic"}}
             countries={["GB","ES", "IT", "DE", "NL", "PL", "PT", "RU", "TR", "FR", "CN", "JP", "AE"]}
             searchPlaceholder="Search for a country" />
          </div>
          <div className={'login-register-container outer-links'}>
            <a href="./" className="gplay"><img alt='google play' src={require('../images/gplay.svg')}></img></a>
            <a href="./" className="appstore"><img alt='apple store' src={require('../images/appstore.svg')}></img></a>
          </div>
          {/*Модальное окно логина*/}
          <IonModal isOpen={showPopover}
            cssClass='login-modal logout logreg'
            onDidDismiss={() => setShowPopover(false)}>
            <div className="modal-container">
            <div className="cards"></div>
              <div className="modal-container-title">
              Logout
              </div>
              <p className="logout-p">Are you sure you want to log out?</p>
              <div onClick={() => logout()} className="login-btn">Logout</div>

            </div>
          </IonModal>
      </div>
    );
}
export default UserActions;
