import { IonContent, IonPage } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../shards-dashboards.1.1.0.css";
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import  Favorites  from '../components/Favorites.js';
import Livecasino from './games/Livecasino.js';
import Tablegames from './games/Tablegames.js';
import Videoslots from './games/Videoslots.js';
import Lotto from './games/Lotto.js';
import Other from './games/Other.js';
import  Banner  from '../components/Banner.js';
import MobileMenu from '../components/MobileMenu';


function Games(props){
  let location = useLocation();
  // const [games, setGames] = useState(props.games);
  const [showGames, setShowGames] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [slots, setSlots] = useState([]);
  const [other, setOther] = useState([]);
  const [lotto, setLotto] = useState([]);
  const [tableGames, setTableGames] = useState([]);
  const [live, setLive] = useState([]);
  const [isCurrent, setIsCurrent] = useState('');
  // const [isCurrentProvider, setIsCurrentProvider] = useState('');
  let filterGames = (e, index, cat, i) => {
    setFiltered(true);
    if (e !== null) {
      whereAreWe(i);
    }
    props.games.map(el => {
      el.tags.forEach(element => {
          if (element.cat === cat){
            element.tag.forEach(tag => {
            if (tag === index) {
              // console.log(tag, index);
              tempArr.push(el)
            }
          })
        };

      });
      return null;
    })
    // setGames(tempArr);
    // console.log(tempArr);
    // setToShow(tempArr);
    let slotsArr = [];
    let tableGamesArr = [];
    let liveArr = [];
    let tempOther = [];
    tempArr.forEach(el => {
        for (let i = 0; i < el.tags.length; i++) {
          if (el.tags[i].cat === 'SLOTS'){
              slotsArr.push(el);
          }
          if (el.tags[i].cat === 'GAMES'){
              tableGamesArr.push(el);
          }
          if (el.tags[i].cat === 'LIVE'){
              liveArr.push(el);
          }
          if (el.tags[i].cat === 'OTHERS'){
              tempOther.push(el);
          }
        }
        return el;
      }
    );
    setSlots(slotsArr);
    // setIsCurrentProvider('');
    setTableGames(tableGamesArr);
    setLive(liveArr);
    setOther(tempOther);
    let yOffset = document.getElementById('scroll-anchor').offsetTop;
    document.getElementsByClassName('gm-pg')[0].scrollToPoint(0, yOffset, 500);
    console.log(yOffset);
  }
  function setPropGames() {
    let checkGames = (item, tag) => {
      let boo = false;
      item.tags.forEach((el, i) => {
        if (el.cat === tag) {
          boo = true;
        }
      });
      return boo;
    }
    setSlots(props.games.filter(item => checkGames(item,'SLOTS')));
    setTableGames(props.games.filter(item => checkGames(item,'GAMES')));
    setLive(props.games.filter(item => checkGames(item,'LIVE')))
    setOther(props.games.filter(item => checkGames(item,'OTHERS')));
    setLotto(props.games.filter(item => checkGames(item,'LOTTO')));
  }/*eslint-disable*/

  useEffect(() => {
    setPropGames();
     if (showGames === true) {
       setShowGames(false)
     }
     if (location.search.length > 0) {
       let str = location.search.slice(1);
       let toSearch = str.split(':');
       let index = toSearch[0];
       let cat = toSearch[1];
       filterGames(null, index, cat, 0);
     }
  },[props.games]);
  //сбрасываем состояние игр до props
  useEffect(() => {
    if (filtered === true) {
      setPropGames();
      setFiltered(false)
    }
  },[location.pathname]);
  /*eslint-enable*/
  useEffect(() => {
    // console.log(location);
     // setGames(props.games);
     setIsCurrent('');
  },[location]);
  let tempArr = [];
  let whereAreWe = (index) => {
    setIsCurrent(index);
  }

    let onScroll = (e) => {
      //slots are shown
      if (document.getElementsByClassName('video-slots-container')[0]) {
        if (window.innerHeight - 300 > document.getElementsByClassName('video-slots-container')[0].getBoundingClientRect().top) {
           if (!document.getElementsByClassName('video-slots-container')[0].classList.contains('animate-now')) {
             document.querySelectorAll('.video-slots-container').forEach(n => n.classList.add('animate-now'));
           }
        }
      }
      if (document.getElementsByClassName('live-casino-container')[0]) {
        if (window.innerHeight - 300 > document.getElementsByClassName('live-casino-container')[0].getBoundingClientRect().top) {
          if (!document.getElementsByClassName('live-casino-container')[0].classList.contains('animate-now')) {
            document.querySelectorAll('.live-casino-container').forEach(n => n.classList.add('animate-now'));
          }
        }
      }
      if (document.getElementsByClassName('table-games-container')[0]) {
        if (window.innerHeight - 300 > document.getElementsByClassName('table-games-container')[0].getBoundingClientRect().top) {
          if (!document.getElementsByClassName('table-games-container')[0].classList.contains('animate-now')) {
            document.querySelectorAll('.table-games-container').forEach(n => n.classList.add('animate-now'));
          }
        }
      }
    }
    return (
      <IonPage className="games-page">
        <IonContent className='gm-pg' id={'content'}  scrollEvents={true} onIonScroll={(e) => onScroll(e)} overflow-scroll="true" class="has-header">

          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            toggle = {props.toggle}
            getInfo={props.getInfo}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            setUuid={props.setUuid}
            page={'Профиль'}
            data={props.data}
            />
            <div className="banner-container">
              <Banner link={'/bonus'} banners={props.banners} mode = {props.mode}/>
              <div className={'banner-info'}>
                {location.pathname === "/games/live-casino" &&
                  <div className='jackpot-roll live-pic'></div>
                }
                {location.pathname === "/games/video-slots" &&
                  <div className='jackpot-roll slots-pic'></div>
                }
                {location.pathname === "/games/table-games" &&
                  <div className='jackpot-roll table-pic'></div>
                }
                {location.pathname === "/games/lotto" &&
                  <div className='jackpot-roll lotto-pic'></div>
                }
                {location.pathname === "/games/other-games" &&
                  <div className='jackpot-roll other-pic'></div>
                }
                <div className='jackpot-title'>
                  <div className='jackpot-top'>
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className='jackpot-bottom'>
                  {location.pathname === "/games/live-casino" && props.data.tags.map((el,i) => {
                    if (el.cat === 'LIVE') {
                      return(
                        <div className={'tag-container ' + (isCurrent.toString() === i.toString() ? "is-current": '' )} data-i={i} onClick = {(e) => filterGames(e, el.tag, el.cat, i)} key={i}>
                          <div  className='tag-img' style={ {backgroundImage: `url(${el.image})`}}></div>
                          <p>{el.tag}</p>
                        </div>
                      )
                    };
                    return null;
                  })
                  }
                  {location.pathname === "/games/table-games" && props.data.tags.map((el,i) => {
                    if (el.cat === 'GAMES') {
                      return(
                        <div className={'tag-container ' + (isCurrent.toString() === i.toString() ? "is-current": '' )} data-i={i} key={i} onClick = {(e) => filterGames(e, el.tag, el.cat, i)}>
                          <div className='tag-img' style={ {backgroundImage: `url(${el.image})`}}></div>
                          <p>{el.tag}</p>
                        </div>
                      )
                    };
                    return null;
                  })
                  }
                  {location.pathname === "/games/video-slots" && props.data.tags.map((el,i) => {
                    if (el.cat === 'SLOTS') {
                      return(
                        <div className={'tag-container ' + (isCurrent.toString() === i.toString() ? "is-current": '' )} data-i={i} key={i} onClick = {(e) => filterGames(e, el.tag, el.cat, i)}>
                          <div className='tag-img' style={ {backgroundImage: `url(${el.image})`}}></div>
                          <p>{el.tag}</p>
                        </div>

                      )
                    };
                    return null;
                  })
                  }
                  {location.pathname === "/games/other-games" && props.data.tags.map((el,i) => {
                    if (el.cat === 'OTHERS') {
                      return(
                        <div className={'tag-container ' + (isCurrent.toString() === i.toString() ? "is-current": '' )} data-i={i} key={i} onClick = {(e) => filterGames(e, el.tag, el.cat, i)}>
                          <div className='tag-img' style={ {backgroundImage: `url(${el.image})`}}></div>
                          <p>{el.tag}</p>
                        </div>
                      )
                    };
                    return null;
                  })
                  }
                  {location.pathname === "/games/lotto" && props.data.tags.map((el,i) => {
                    if (el.cat === 'LOTTO') {
                      return(
                        <div className={'tag-container ' + (isCurrent.toString() === i.toString() ? "is-current": '' )} data-i={i} key={i} onClick = {(e) => filterGames(e, el.tag, el.cat, i)}>
                          <div className='tag-img' style={ {backgroundImage: `url(${el.image})`}}></div>
                          <p>{el.tag}</p>
                        </div>
                      )
                    };
                    return null;
                  })
                  }
                  </div>
                </div>
              </div>
            </div>
            <Favorites getInfo={props.getInfo} data={props.data} setShowLoading={props.setShowLoading} setFavsArray={props.setFavsArray} getGames={props.getGames} token={props.data.token} openGame={props.openGame} games={props.games}/>

            <br/>
            <p id='scroll-anchor'></p>
            {location.pathname === "/games/live-casino" &&
              <Livecasino getInfo={props.getInfo} openGame={props.openGame} data={props.data} userData={props.data.userData} favs={props.favs} getGames={props.getGames} setShowLoading={props.setShowLoading} token={props.data.token} games={live}/>
            }
            {location.pathname === "/games/video-slots" &&
              <Videoslots getInfo={props.getInfo} openGame={props.openGame} data={props.data} userData={props.data.userData} favs={props.favs} getGames={props.getGames} setShowLoading={props.setShowLoading} token={props.data.token} games={slots}/>
            }
            {location.pathname === "/games/table-games" &&
              <Tablegames getInfo={props.getInfo} openGame={props.openGame} data={props.data} userData={props.data.userData} favs={props.favs} getGames={props.getGames} setShowLoading={props.setShowLoading} token={props.data.token} games={tableGames}/>
            }
            {location.pathname === "/games/lotto" &&
              <Lotto getInfo={props.getInfo} openGame={props.openGame} data={props.data} userData={props.data.userData} favs={props.favs} getGames={props.getGames} setShowLoading={props.setShowLoading} token={props.data.token} games={lotto}/>
            }
            {location.pathname === "/games/other-games" &&
              <Other getInfo={props.getInfo} openGame={props.openGame} data={props.data} userData={props.data.userData} favs={props.favs} getGames={props.getGames} setShowLoading={props.setShowLoading} token={props.data.token} games={other}/>
            }
            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default Games;
