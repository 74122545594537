import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import  UserActions  from '../components/UserActions.js';

function Page(props) {


  return (
    <IonPage>
    <IonHeader>
      <IonToolbar className={'toolbar-container'}>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle className={"capitalize"}>{props.name}</IonTitle>
        <IonButtons slot="end">
          <UserActions userDetails={props.data}/>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
      <IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Page;
