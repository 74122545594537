import { IonContent, IonPage,IonIcon,IonToast } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import {  star } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import url from '../axios.js';
import { Redirect} from 'react-router-dom';
import axios from 'axios';
import "../shards-dashboards.1.1.0.css";
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import  Banner  from '../components/Banner.js';
import MobileMenu from '../components/MobileMenu';
import  GameModal  from '../components/GameModal.js';

function Favorites(props){
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState('');
  const [changeCurrencyMess, setChangeCurrencyMess] = useState(false);
  const [games, setGames] = useState(props.data.favs);
  const [showGames, setShowGames] = useState(false);
  const [html, setHtml] = useState('');
  // const [favorites, setFavorites] = useState([]);
  useEffect(() => {
     setGames(props.data.favs);
  },[props.data.favs]);
  function showGame(e){
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-game',
            headers: {
                 'Authorization': `Bearer ${props.data.token}`,
             },
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            setGameUrl(res.data.data.url);
            // props.setShowLoading(false);
            setIsOpen(true);
          })
          .catch((error) => {
            if (error.response.data.error.includes('Currency not supported') || error.response.data.error.includes('Not supported currency')  ) {
              props.setShowLoading(false);
              setChangeCurrencyMess(true);
            }

           })
    // history.push(`/game/${+e.target.getAttribute("data-id")}`);
  }
  function showDemoGame(e) {
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
            method: 'post',
            url: url + '/api/run-demo-game',
            headers: {
                 'Authorization': `Bearer ${props.data.token}`,
             },
            data: {
              id: +e.target.getAttribute("data-id"),
              lang: props.data.applang,
              currency: props.data.userData.currency_id
            }
          })
          .then(res => {
            if (res.data.data.linkType === 'URL') {
              setGameUrl(res.data.data.url);
            }
            if (res.data.data.linkType === 'HTML') {
              setGameUrl('');
              setHtml(res.data.data.html);
            }
            // props.setShowLoading(false);
            setIsOpen(true);
          })
  }

  useEffect(() => {
     setGames(props.data.favs);
     if (showGames === true) {
       setShowGames(false)
     }
     // if (location.search.length > 0) {
     //   let str = location.search.slice(1);
     //   let toSearch = str.split(':');
     //   let index = toSearch[0];
     //   let cat = toSearch[1];
     //
     // }
  },[props.data.favs, showGames]);


    return (
      <IonPage className="games-page fvs">
      {props.data.token === null &&
        <Redirect to="/home"/>
      }
        <IonContent id={'content'}  scrollEvents={true} overflow-scroll="true" class="has-header">
          {/**/}
          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            toggle = {props.toggle}
            getInfo={props.getInfo}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            setUuid={props.setUuid}
            page={'Профиль'}
            data={props.data}
            />
            <div className="banner-container">
              <Banner link={'/bonus'} banners={props.banners} mode = {props.mode}/>
              <div className={'banner-info'}>
                <div className='jackpot-roll other-pic'></div>
                <div className='jackpot-title'>
                  <div className='jackpot-top'>
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/premium'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className='jackpot-bottom'>

                  </div>
                </div>
              </div>
            </div>

            <br/>
            <h1 className="favs">Your favorite games</h1>
            <div className="video-slots-container">
            <div className="bg-container pic-234"></div>


              {
                games.map((el,i) => {

                    return(
                      <div key={i} className={'game'}>

                        <div

                          className={'main-box'}
                          >
                          {el.recommended === 1 && <p className="star"><IonIcon size="large" icon={star}></IonIcon></p>}
                          <div className={' blurred'} style={ {backgroundImage: `url(${el.banner_url})`}}></div>
                          <div className='topleft'>
                            <img alt={'provider'} src={  `${process.env.PUBLIC_URL}/assets/provider-logos/${el.provider}.png`} className="provider-logo"></img>
                          </div>
                            <img className='game-preview' alt={el.name} src={`${el.banner_url}`}></img>
                          <div data-fav={el.favorite} data-game-id={el.id} className={'game-hovered'}>
                            <div className='btns'>
                              {props.data.token !== null && <div data-id={el.id} onClick={(e) => showGame(e)} className={'play-game'}>Play</div>}
                            {el.hasDemo === "1" &&  <div data-id={el.id} onClick={(e) => showDemoGame(e)} className={'play-game demo'}>Demo</div>}
                            </div>
                          </div>
                        </div>
                        <div data-fav={el.favorite} data-game-id={el.id} className="bottom">
                          <p>{el.name}</p>

                        </div>

                      </div>)

                  })
              }
              <IonToast
                isOpen={changeCurrencyMess}
                onDidDismiss={() => setChangeCurrencyMess(false)}
                message={'Your favorite currency is not supported by this game. Please change your favorite currency.'}
                color='orange'
                duration={5000}
                buttons={[
                  {
                    side: 'end',
                    text: 'Change currency',
                    handler: () => history.push('/user/personal-details')
                  }
                ]}
              />
              <GameModal setShowLoading={props.setShowLoading} setGameUrl={setGameUrl} setHtml={setHtml} html={html} token={props.data.token} getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default Favorites;
