import {
  IonMenuButton,
  IonButtons,
  IonItem,
  IonToggle,
} from '@ionic/react';
import React from 'react';

export default function MobileMenu(props) {
  function toggleDarkMode1(){
    if (props.data.mode === true) {
      props.toggleMode(false);
    } if (props.data.mode === false) {
      props.toggleMode(true);
    }
  }

  return (
    <div id={'header-mobile'} className='mobile-header'>
      <IonButtons className={'only-mobile'} slot="start">
        <IonMenuButton color={'dark'} />
      </IonButtons>
      <div className={'logo only-mobile'}>
        <IonItem
          lines="none"
          routerLink="/home"
          className="home-link">
        </IonItem>
      </div>
      <IonToggle
        checked={props.data.mode}
        onIonChange={() => toggleDarkMode1()}
        className={'themeToggle only-mobile'}
        ion-toggle-text
        ng-model="simple">
      </IonToggle>
    </div>
  )
}
