import React, { useEffect, useState } from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import './css/HeaderMenu.css';
// import { HashLink as Link } from 'react-router-hash-link';
import { Link } from "react-router-dom";

function MenuHeader(props) {
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [balanceSingle, setBalanceSingle] = useState(0);
  const [bonusTotal, setBonusTotal] = useState(0);

  useEffect(() => {
    let balance = 0;
    let bonus = 0;
    let balSingle = 0;
    props.data.balance.forEach((item, i) => {
      balance += +item.ob_priv;
      bonus += +item.ob_priv_b;
    });
    props.data.balance.forEach((item, i) => {
      balSingle = item.ob
    });

    setBalanceTotal(balance);
    setBalanceSingle(balSingle);
    setBonusTotal(bonus);
  }, [props.data.balance])

  let userCurrency = props.data.userData.currency_id;
  let vle = props.data.balance.length > 1 ? `${"~"+getCurrency(props.data.userData.currency_id)+" "+ balanceTotal.toFixed(2)}` : `${balanceSingle +" "+ getCurrency(userCurrency)}`;

  function getCurrencyValue(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  function getCurrency(currency_id){
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  return (
        <div id='header-menu' className="only-desktop">
          <div className="header-menu-top">
            <Link to={'/games/video-slots'} className={'videoSlots hvr-float-shadow'}>
              <div className={'box'}></div>
              <p>Slots</p>
            </Link>
            <Link className={'tableGames hvr-float-shadow'} to={'/games/table-games'}>
              <div className={'box'}></div>
              <p>
                Games
              </p>
            </Link>
            <Link className={'liveCasino hvr-float-shadow'} to={'/games/live-casino'}>
              <div className={'box'}></div>
              <p>
                Live
              </p>
            </Link>
            {/*<Link to={'/games/lotto'} className={"lotto hvr-float-shadow"}>
              <div className={'box'}></div>
              <p>
                Bingo
              </p>
            </Link>*/}
            <Link to={'/games/other-games'} className={"other-games hvr-float-shadow"}>
              <div className={'box'}></div>
              <p>
                Other
              </p>
            </Link>
            <a href="https://www.sbet.biz/home" target="_blank" rel="noopener noreferrer" className={"bets-red hvr-float-shadow"}>
              <div className={'box'}></div>
              <p>
                Bets
              </p>
            </a>
          </div>
          {
          props.data.token !== null &&
          <div className="header-menu-bottom">
          <p className={"header-balance " + (props.isGame === false ? "" : 'in-game-balance')}>
            Balance {props.isGame === false ? vle : 'in game window'}
          </p>
          {  props.isGame === false && <IonSelect
              onIonChange={e => vle = props.data.userData.currency_id}
              value={''}
              className={'header-search country cur'}
              interface="popover">
              {
                props.data.balance.map(el => {
                  if (el.currency_id === props.data.userData.currency_id && el.credit_time !== null) {
                    return (
                      <IonSelectOption key={'loan'} disabled={true} value={el.currency_id}>Loan: {el.credit_value} {getCurrency(el.currency_id)}</IonSelectOption>
                    )
                  }
                })
              }
              {
                props.data.balance.map((el, i) => {

                  return (
                      <IonSelectOption key={i} disabled={true} value={el.currency_id}>Deposit in {getCurrencyValue(el.currency_id)}: {el.ob} {getCurrency(el.currency_id)}</IonSelectOption>
                  )
                })
              }
                {props.data.balance.length > 1 && <IonSelectOption disabled={true} value='total'>~{getCurrency(props.data.userData.currency_id)}  {balanceTotal.toFixed(2)}</IonSelectOption>}
            </IonSelect>}
            <div className="coins"></div>
            <p>Bonus {getCurrency(props.data.userData.currency_id)}  {bonusTotal.toFixed(2)}</p>
            <Link to={'/user/deposit-money'} className={'deposit-money-button'}>Deposit</Link>
          </div>
          }
        </div>
        )

};

export default MenuHeader;
