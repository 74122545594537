// import Bootstrap from 'bootstrap';
import { render } from 'react-dom';
import React from 'react';
import Casino from './Casino.js';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
// import { Router, Route, browserHistory } from 'react-router';

if (document.getElementById('react-root')) {
    render(<Casino  />, document.getElementById('react-root'));
}
