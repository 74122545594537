import React, {useState} from 'react';
import { IonSelect, IonInput,IonLabel,IonToast, IonItem, IonSelectOption } from '@ionic/react';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import axios from 'axios';
import url from '../../../axios.js';
import depositIcon from '../../../images/icons/deposit2.svg';

function DepositMoney(props){
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast2Text, setShowToast2Text] = useState(false);
  const [currency, setCurrency] = useState();
  const [moneyValue, setMoneyValue] = useState();
  const [amount, setAmount] = useState();
  function deposit() {
     if (currency === undefined || currency === "") {
       setCurrency('');
    } if (moneyValue === undefined || moneyValue === "") {
       setMoneyValue('');
    } if (currency !== undefined &&
    currency !== '' &&
    moneyValue !== undefined &&
    moneyValue !== '') {
      props.setShowLoading(true)
      axios({
            method: 'post',
            url: url + "/api/deposit-money",
            headers: {
                 'Authorization': `Bearer ${props.data.token}`,
             },
            data: {
              currency: currency,
              amount: amount,
            }
          })
          .then(res => {
            props.updateUser(props.data.token);
            setShowToast1(true);
            setMoneyValue(null);
            props.setShowLoading(false);
          })
          .catch(error => {
            /*422*/
            props.setShowLoading(false);
            setShowToast2Text(error.response.data.error);
            if (error.response.status === 500 ) {
              setShowToast2Text('Please validate your account');
            }
            setShowToast2(true);
          });
          props.setShowLoading(false)
    }
  }
  function getCurrency(currency_id) {
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  function withNulls(e) {
    setMoneyValue(e.detail.value);
    setAmount(e.detail.value)
    // (+e.detail.value).toFixed(2)
  }
    return (
      <div className="deposit-money">
        <div className="shade">
          <p className="top-shade">
            <img alt='deposit' src={depositIcon} className="deposit-icon" />
            <span>Deposit</span>
          </p>
          <p className="bottom-shade">Money</p>
        </div>
        <p className="title">
          <span>Deposit Money</span>
        </p>
        <div className='account-details-container closure'>
          <div className='step-input-container'>
            <p className='step-input-title'>Currency</p>
            <IonSelect
            onIonChange={e => setCurrency(e.detail.value)}
            value={currency}
            placeholder={'$'}
            className={'header-search country cur ' + (currency !== undefined && currency === '' ? "wrong" : '')}
            interface="popover">
            {props.data.currencies.map((el,i)=> {
              return <IonSelectOption key={i} value={el.id}>{getCurrency(el.id)}</IonSelectOption>
            })}
            </IonSelect>
          </div>
          <div className='step-input-container'>
            <p className='step-input-title'>Value</p>
            <IonInput
              className={'money-value header-search ' + (moneyValue !== undefined && moneyValue === '' ? "wrong" : '')}
              type="text"
              value={moneyValue}
              placeholder="Value"
              onIonChange={e => withNulls(e)}
              onIonBlur={() => setMoneyValue((+moneyValue).toFixed(2) === 'NaN' ? '0.00' : (+moneyValue).toFixed(2))}
              >
            </IonInput>
          </div>
          <div className='step-input-container'>
            <IonItem
              button
              onClick={() => deposit()}
              className="register-btn submit depst">
              <IonLabel>Deposit</IonLabel>
            </IonItem>
          </div>
        </div>
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message={"Your deposit has been submitted. "}
          color='primary'
          duration={10000}
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message={showToast2Text}
          color='danger'
          duration={10000}
        />
      </div>
    );
};

export default DepositMoney;
