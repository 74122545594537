import axios from 'axios';

// var url = 'http://casino.test';
var url = 'https://test.bingo.bet';
axios.interceptors.response.use(response => {
        // console.log(document.getElementById("lhc_container_v2"));
        // console.log(response.config.url);
  if (response.data.message === "Unauthenticated.") {
    console.log("Unauthenticated.");
  }
  return response;
}, function (error, response) {
  console.log(error.response.status);
    if (error.response.status === 401) {
      alert("Your session is expired. Please log in.")
      setTimeout(() => {
        localStorage.clear();
        window.location.href="/home";
      }, 2000);
    }
   return Promise.reject(error);
 });

// var sendPost = (api, data = {}, headers) => {
//   url = url + api;
//   var body = {
    // info: {
    //   "key" : localStorage.getItem("key"),
    // },
//     ...data };
//   return axios.post(url, body, {
//     headers: headers
//   });
// }


// axios.interceptors.request.use(request => {
//        return request;
//    }, error => {
//        console.log(error);
//        return Promise.reject(error);
//    });

// axios.interceptors.response.use(function (response) {
//    // Any status code that lie within the range of 2xx cause this function to trigger
//    // Do something with response data
//    return response;
//  }, function (error) {
//    // Any status codes that falls outside the range of 2xx cause this function to trigger
//    // Do something with response error
//    return Promise.reject(error);
//  });

export default url
