import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
  <div className="point-map" style={{
    color: 'white',
    width: '30px',
    height: '30px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 12.131439,
      lng: -68.896085
    },
    zoom: 16
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyChBSRi1qhIbP4lkbCmhGBIA-A1ZaAN78Y' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent

            lat={12.131439}
            lng={-68.896085}
            text=""
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
