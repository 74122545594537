import { IonContent, IonPage } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import "../shards-dashboards.1.1.0.css";
import  Banner  from '../components/Banner.js';
import  Favorites  from '../components/Favorites.js';
import  Header  from '../components/Header.js';
import  LatestWinners  from '../components/LatestWinners.js';
import  Footer  from '../components/Footer.js';
import Livecasino from './games/Livecasino.js';
import Tablegames from './games/Tablegames.js';
import Videoslots from './games/Videoslots.js';
import '../components/css/Main.css';
import MobileMenu from '../components/MobileMenu';

function Main(props) {
  let location = useLocation();
  // const [slots, setSlots] = useState([]);
  // const [tableGames, setTableGames] = useState([]);
  // const [live, setLive] = useState([]);
  // const [showGames, setShowGames] = useState(false);
  const [smallHeader] = useState(false);

  useEffect(() => {
     if (document.querySelectorAll('.animate-now').length !== 0) {
       document.querySelectorAll('.animate-now').forEach(n => n.classList.remove('animate-now'));
     }
     document.getElementById('header-mobile').scrollIntoView({block: "start", behavior: "smooth"});
  },[location.pathname]);


   let onScroll = (e) => {
     //slots are shown
     if (window.innerHeight - 300 > document.getElementsByClassName('video-slots-container')[0].getBoundingClientRect().top) {
        if (!document.getElementsByClassName('video-slots-container')[0].classList.contains('animate-now')) {
          document.querySelectorAll('.video-slots-container').forEach(n => n.classList.add('animate-now'));
        }
     }
     if (window.innerHeight - 300 > document.getElementsByClassName('live-casino-container')[0].getBoundingClientRect().top) {
       if (!document.getElementsByClassName('live-casino-container')[0].classList.contains('animate-now')) {
         document.querySelectorAll('.live-casino-container').forEach(n => n.classList.add('animate-now'));
       }
     }
     if (window.innerHeight - 300 > document.getElementsByClassName('table-games-container')[0].getBoundingClientRect().top) {
       if (!document.getElementsByClassName('table-games-container')[0].classList.contains('animate-now')) {
         document.querySelectorAll('.table-games-container').forEach(n => n.classList.add('animate-now'));
       }
     }
   }
    return (
      <IonPage>
        <IonContent
          scrollEvents={true}
          onIonScroll={(e) => onScroll(e)}
          id={'content'}
          overflow-scroll="false"
          className="main-page has-header">
          {/**/}
          <MobileMenu
          changeAppLang={props.changeAppLang}
          setShowLoading={props.setShowLoading}
          setUuid={props.setUuid}
          setToken={props.setToken}
          data={props.data}
          toggleMode={props.toggleMode}/>
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            toggle = {props.toggle}
            smallHeader={smallHeader}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            getInfo={props.getInfo}
            setUuid={props.setUuid}
            page={'Профиль'}
            data={props.data}
            />
            <div className={"banner-container main " + (smallHeader === true ? "pd-top" : '')} >
              <Banner link={'/bonus'} banners={props.banners} mode = {props.mode}/>
              <div className={'banner-info '} >
                <div className='jackpot-roll'></div>
                <div className='jackpot-title'>
                  <div className={'jackpot-top '} >
                    <div className='jackpot-text'>
                      <p>JACKPOT {props.jackPotValue} $ </p>
                      <Link to={'/jackpots'} className='jackpot-playnow'></Link>
                    </div>
                    <div className='jackpot-links'>
                      <Link className="tourney" to={'/tournaments'}>
                        <div className={'bx'}></div>
                        <p>
                          Tournaments
                        </p>
                      </Link>
                      <Link className="premium" to={'/premium'}>
                        <div className={'bx'}></div>
                        <p>
                          Premium
                        </p>
                      </Link>
                      <Link className="providers" to={'/providers'}>
                        <div className={'bx'}></div>
                        <p>
                          providers
                        </p>
                      </Link>
                      <Link className="bonuses" to={'/bonus'}>
                        <div className={'bx'}></div>
                        <p>
                          bonuses
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Favorites favs={props.favs} getInfo={props.getInfo} data={props.data} setShowLoading={props.setShowLoading} setFavsArray={props.setFavsArray} getGames={props.getGames} token={props.data.token} openGame={props.openGame} games={props.games}/>
            <Videoslots
              getInfo={props.getInfo}
              data={props.data}
              openGame={props.openGame}
              userData={props.data.userData}
              favs={props.favs}
              getGames={props.getGames}
              setShowLoading={props.setShowLoading}
              token={props.data.token}
              games={
                props.games.filter(item => {
                let boo = false;
                item.tags.forEach((el, i) => {
                  if (el.cat === 'SLOTS') {
                    boo = true;
                  }
                });
                return boo;
              }
            )
              }
              />
            <LatestWinners
              getInfo={props.getInfo}
              data={props.data}
              setShowLoading={props.setShowLoading}
              openGame={props.openGame}
              token={props.data.token}
              games={props.games}
              winners = {props.winners}
              jackPotValue = {props.jackPotValue}
              />
            <Livecasino
              getInfo={props.getInfo}
              data={props.data}
              openGame={props.openGame}
              userData={props.data.userData}
              favs={props.favs}
              getGames={props.getGames}
              setShowLoading={props.setShowLoading}
              token={props.data.token}
              games={props.games.filter(item => {
                let boo = false;
                item.tags.forEach((el, i) => {
                  if (el.cat === 'LIVE') {
                    boo = true;
                  }
                });
                return boo;
              })}
              />
            {/* <Bonuses timeleft={props.timeleft} tournaments={props.tournaments} bonuses={props.data.bonuses}/> */}
            <Banner link={'/tournaments'} banners={props.advs} mode = {props.mode}/>
            <Tablegames
              getInfo={props.getInfo}
              data={props.data}
              openGame={props.openGame}
              userData={props.data.userData}
              favs={props.favs}
              getGames={props.getGames}
              setShowLoading={props.setShowLoading}
              token={props.data.token}
              games={props.games.filter(item  => {
                let boo = false;
                item.tags.forEach((el, i) => {
                  if (el.cat === 'GAMES') {
                    boo = true;
                  }
                });
                return boo;
              })}
              />

            <Footer providers={props.data.providersList}/>
        </IonContent>
      </IonPage>
    );
};

export default Main;
