import { IonContent, IonPage } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import "../shards-dashboards.1.1.0.css";
import  Header  from '../components/Header.js';
import  Footer  from '../components/Footer.js';
import '../components/css/Main.css';
import url from '../axios.js';
import axios from 'axios';
import MobileMenu from '../components/MobileMenu';
import SimpleMap from '../components/Map'

function Documents(props) {
  const [text, setText] = useState([]);

  let location = useLocation();

    // DB::table('languages')->insert(['id' => 'en', 'name' => 'Английский язык',]);
    //   DB::table('languages')->insert(['id' => 'ru', 'name' => 'Русский язык',]);
    //   DB::table('languages')->insert(['id' => 'es', 'name' => 'Испанский язык',]);
    //   DB::table('languages')->insert(['id' => 'it', 'name' => 'Итальянский язык',]);
    //   DB::table('languages')->insert(['id' => 'fr', 'name' => 'Французский язык',]);
    //   DB::table('languages')->insert(['id' => 'de', 'name' => 'Немецкий язык',]);
    //   DB::table('languages')->insert(['id' => 'tr', 'name' => 'Турецкий язык',]);
    let propsDoc = props.fromDocs;
    let propsLoading = props.setShowLoading;
    useEffect(() => {
      let documentId;
      function getDocs(){
          if (location.pathname === '/documents/about') {
            documentId = 5;
          }
          if (location.pathname === '/documents/kyc-policy') {
            documentId = 9;
          }
          if (location.pathname === '/documents/responsible-policy') {
            documentId = 8;
          }
          if (location.pathname === '/documents/bonus') {
            documentId = 4;
          }
          if (location.pathname === '/documents/cookie-policy') {
            documentId = 6;
          }
          if (location.pathname === '/documents/contributions') {
            documentId = 3;
          }
          if (location.pathname === '/documents/disclaimer') {
            documentId = 7;
          }
          if (location.pathname === '/documents/policy') {
            documentId = 2;
          }
          if (location.pathname === '/documents/terms') {
            documentId = 1;
          }
          if (location.pathname === '/documents/tournaments') {
            documentId = 11;
          }
          if (location.pathname === '/documents/data-protection') {
            documentId = 12;
          }
          if (location.pathname === '/documents/help') {
            documentId = 13;
          }
        propsLoading(true);
        console.log("success");
          axios({
                method: 'post',
                url: url + '/api/get-documents',
                data: {
                  id: documentId,
                  lang: 'GB'
                }
              })
              .then(res => {
                setText(JSON.parse(Object.values(res.data.data)));
                propsLoading(false);
               })
        }
      if (location.pathname === '/documents/about' ||
          location.pathname === '/documents/kyc-policy' ||
          location.pathname === '/documents/bonus' ||
          location.pathname === '/documents/responsible-policy' ||
          location.pathname === '/documents/cookie-policy' ||
          location.pathname === '/documents/contributions' ||
          location.pathname === '/documents/disclaimer' ||
          location.pathname === '/documents/policy' ||
          location.pathname === '/documents/terms' ||
          location.pathname === '/documents/tournaments' ||
          location.pathname ==='/documents/data-protection' ||
          location.pathname === '/documents/help' ){
            getDocs();
            document.getElementsByClassName('toscroll')[0].scrollIntoView(true);
            document.getElementById('header-mobile').scrollIntoView(true);
            propsDoc(false);

      } else {
        propsDoc(true)
      }
    }, [location.pathname, propsDoc, propsLoading]);
    function toggleHeight(e, i, content) {
      if (content !== null) {
        let count = +e.target.parentElement.getAttribute("data-p");
        document.getElementsByClassName("paragraph")[count].classList.toggle('opened');
      }
    }
    return (
      <IonPage>
        <IonContent overflow-scroll="true" class="has-header">
          {/**/}
          <MobileMenu toggleMode={props.toggleMode} changeAppLang={props.changeAppLang} setShowLoading={props.setShowLoading} setUuid={props.setUuid} setToken={props.setToken} data={props.data} />
          <Header
            setMainLogin={props.setMainLogin}
            changeAppLang={props.changeAppLang}
            toggle = {props.toggle}
            getInfo={props.getInfo}
            openGame={props.openGame}
            toggleMode={props.toggleMode}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setToken={props.setToken}
            page={'Профиль'}
            data={props.data}
            />

            <div className="toscroll"></div>
            <div className="bets-container terms">

            {text.map((el,i) => {
              return (
                <span className={"paragraph " + (el.opened === true ? 'opened' : '')} data-p={i} key={i}>
                  {el.name !== '' ? <h3 className={(el.contents === null ? '': 'h3-smaller')} onClick={(e) => toggleHeight(e, i, el.contents )}>{el.name}</h3> :'' }
                  {el.contents !== '' ? <p dangerouslySetInnerHTML={{__html: el.contents}}></p>: '' }
                </span>
              )
            })}
            {location.pathname === '/documents/about' && <div className='bg-container pic-14'></div>}
              {location.pathname === '/documents/bonus' && <div className='bg-container 234'></div>}
              {location.pathname === '/documents/cookie-policy' && <div className='bg-container pic-234'></div>}
              {location.pathname === '/documents/contributions' && <div className='bg-container pic-123'></div>}
              {location.pathname === '/documents/responsible-policy' && <div className='bg-container pic-2324'></div>}
              {location.pathname === '/documents/data-protection' && <div className='bg-container pic-14'></div>}
              {location.pathname === '/documents/disclaimer' && <div className='bg-container pic-33'></div>}
              {location.pathname === '/documents/policy' && <div className='bg-container pic-22'></div>}
              {location.pathname === '/documents/help' && <div className='bg-container pic-22'></div>}
              {location.pathname === '/documents/kyc-policy' && <div className='bg-container pic-123'></div>}
              {location.pathname === '/documents/terms' && <div className='bg-container pic-2324'></div>}
              {location.pathname === '/documents/tournaments' && <div className='bg-container pic-123'></div>}
              {location.pathname === '/documents/about' &&
              <div className='map-general'>
                <SimpleMap/>
              </div>
              }
          </div>

          <Footer providers={props.data.providersList}/>
          </IonContent>
      </IonPage>
    );
};

export default Documents;
