import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IonIcon, IonToast } from "@ionic/react";
import "../../components/css/Main.css";
import url from "../../axios.js";
import axios from "axios";
import { heartOutline, heart, star } from "ionicons/icons";
import GameModal from "../../components/GameModal.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Livecasino(props) {
  let location = useLocation();
  const history = useHistory();
  const [changeCurrencyMess, setChangeCurrencyMess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState("");
  const [html, setHtml] = useState("");
  var countForMainPage =
    location.pathname === "/games/live-casino" ||
    location.pathname === "/providers"
      ? 49
      : 10;
  const [count, setCount] = useState(countForMainPage);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    var tempFavs = [];
    props.games.map(el => {
      if (el.favorite === 1) {
        tempFavs.push(el.id);
        setFavorites(tempFavs);
      }
      return el.id;
    });
    if (tempFavs.length === 0) {
      setFavorites([]);
    }
    setCount(location.pathname === "/games/video-slots" ? 49 : 10);
    tempFavs = [];
  }, [location.pathname, props.games]);
  var t = 0;
  const getGames = games => {
    let content = [];
    props.games.forEach((el, i) => {
      if (i < count) {
        if (t < 10) {
          t = t + 1;
        }
        if (t === 10) {
          t = t * 0;
        }
        content.push(
          <div
            key={i}
            className={
              "game " +
              (el.large_image === 1 && count > 10 ? "doubled " : "")

            }
          >
            <div className={"main-box"}>
              {el.recommended === 1 && (
                <p className="star">
                  <IonIcon size="large" icon={star} />
                </p>
              )}
              <div
                className={" blurred"}
                style={{ backgroundImage: `url(${el.banner_url})` }}
              />

              <div className="topleft">
                <img
                  alt="provider logo"
                  src={`${process.env.PUBLIC_URL}/assets/provider-logos/${
                    el.provider
                  }.png`}
                  className="provider-logo"
                />
              </div>
              <LazyLoadImage
                delayTime='0'
                className='game-preview'
                alt={el.name}
                effect={"blur"}
                src={`${el.banner_url}`}
                />
              <div
                data-fav={el.favorite}
                data-game-id={el.id}
                className={"game-hovered"}
              >
                <div className="btns">
                  {props.token !== null && (
                    <div
                      data-id={el.id}
                      onClick={e => showGame(e)}
                      className={"play-game"}
                    >
                      Play
                    </div>
                  )}
                  {el.hasDemo === "1" && (
                    <div
                      data-id={el.id}
                      onClick={e => showDemoGame(e)}
                      className={"play-game demo"}
                    >
                      Demo
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div data-fav={el.favorite} data-game-id={el.id} className="bottom">
              <p>{el.name}</p>

              {props.token !== null && (
                <IonIcon
                  onClick={e => pickedFav(e)}
                  size="large"
                  icon={favorites.indexOf(+el.id) !== -1 ? heart : heartOutline}
                />
              )}
            </div>
          </div>
        );
      }
    });
    return content;
  };
  function sendFavs(id, fav) {
    axios({
      method: "post",
      url: url + "/api/set-favorite",
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      data: {
        id: id,
        favorite: +fav
      }
    }).then(res => {
      console.log(res);
      props.getGames(true, props.token);
    });
  }
  function pickedFav(e) {
    let gameID = e.target.parentNode.getAttribute("data-game-id");
    let index = favorites.indexOf(
      +e.target.parentNode.getAttribute("data-game-id")
    );
    if (index === -1) {
      let arr = favorites;
      arr.push(+e.target.parentNode.getAttribute("data-game-id"));
      setFavorites(arr);
      sendFavs(gameID, 1);
      e.target.icon = heart;
    }
    if (index !== -1) {
      let arr = favorites;
      arr.splice(index, 1);
      sendFavs(gameID, 0);
      setFavorites(arr);
      e.target.icon = heartOutline;
    }
  }
  function showGame(e) {
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/run-game",
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      data: {
        id: +e.target.getAttribute("data-id"),
        lang: props.data.applang,
        currency: props.data.userData.currency_id
      }
    }).then(res => {
      if (res.data.data.linkType === "URL") {
        setGameUrl(res.data.data.url);
      }
      if (res.data.data.linkType === "HTML") {
        setGameUrl("");
        setHtml(res.data.data.html);
      }
      // props.setShowLoading(false);
      setIsOpen(true);
    })
    .catch((error) => {
      if (error.response.data.error.includes('Currency not supported') || error.response.data.error.includes('Not supported currency')  ) {
        props.setShowLoading(false);
        setChangeCurrencyMess(true);
      }

     })
    // history.push(`/game/${+e.target.getAttribute("data-id")}`);
  }
  function showDemoGame(e) {
    props.openGame(+e.target.getAttribute("data-id"));
    props.setShowLoading(true);
    axios({
      method: "post",
      url: url + "/api/run-demo-game",
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      data: {
        id: +e.target.getAttribute("data-id"),
        lang: props.data.applang,
        currency: props.data.userData.currency_id
      }
    }).then(res => {
      if (res.data.data.linkType === "URL") {
        setGameUrl(res.data.data.url);
      }
      if (res.data.data.linkType === "HTML") {
        setGameUrl("");
        setHtml(res.data.data.html);
      }
      // props.setShowLoading(false);
      setIsOpen(true);
    });
  }
  function getHeight() {
    if (location.pathname === '/home' || location.pathname.includes('/games')) {
      document.getElementById('content').getScrollElement().then(res => {
        document.getElementById('content').scrollToPoint(0, res.scrollTop, 0);
        setCount(count + 50);
      });
    };
    if (location.pathname === '/providers') {
      document.getElementById('providers-page').getScrollElement().then(res => {
        document.getElementById('providers-page').scrollToPoint(0, res.scrollTop, 0);
        setCount(count + 50);
      });
    }
  }
  return (
    <div className="live-casino-container">
      <div className="bg-container pic-123" />
      <div id="live-casino">
        <div className={"main-box"} />
        <p className="main-type">Live</p>
      </div>
      {getGames(props.games)}
      {count < props.games.length && (
        <div className="add-games">
          <div onClick={() => getHeight()} className={"main-box plus"}>
            <p className="plus">+</p>
          </div>
        </div>
      )}
      <IonToast
        isOpen={changeCurrencyMess}
        onDidDismiss={() => setChangeCurrencyMess(false)}
        message={'Your favorite currency is not supported by this game. Please change your favorite currency.'}
        color='orange'
        duration={5000}
        buttons={[
          {
            side: 'end',
            text: 'Change currency',
            handler: () => history.push('/user/personal-details')
          }
        ]}
      />
      <GameModal
        setGameUrl={setGameUrl}
        setHtml={setHtml}
        html={html}
        token={props.token}
        getInfo={props.getInfo}
        setShowLoading={props.setShowLoading}
        gameUrl={gameUrl}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}

export default Livecasino;
