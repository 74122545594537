import { IonButtons,IonItem, IonSearchbar, IonHeader, IonMenuButton, IonToast, IonToggle,IonModal,IonInput } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory} from "react-router-dom";
import { FP } from "@fp-pro/client";
// import { HashLink as Link } from 'react-router-hash-link';
import  UserActions  from './UserActions.js';
import  MenuHeader  from './MenuHeader.js';
import "bootstrap/dist/css/bootstrap.min.css";
import './css/Header.css';
import axios from 'axios';
import url from '../axios.js';
import GameModal from './GameModal.js'
const uuidv4 = require('uuid/v4');

export default function Header(props){
  let history = useHistory();
  let location = useLocation();
  function toggleDarkMode(){
    if (props.data.mode === true) {
      props.toggleMode(false);
    } if (props.data.mode === false) {
      props.toggleMode(true);
    }
  }
  const [text, setText] = useState('');
  const [foundGames, setFoundGames] = useState([]);
  const [restoreError, setRestoreError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState('');
  // const [loginModal, setLoginModal] = useState(false);
  const [html, setHtml] = useState('');
  const [registerModal, setRegisterModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restore, setRestore] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const [confirmation, setConfirmation] = useState();
  const [showToast, setShowToast] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);

  useEffect(() => {
    function searchGame(e) {
      let temp = [];
      if (e !== '') {
        props.data.games.forEach(item => {
          if (item.name.toLowerCase().indexOf(e.toLowerCase()) > -1) {
            temp.push(item);
          }
        });
        setFoundGames(temp);
      }
      if (e === '') {
        setFoundGames([]);
      }
    }
    searchGame(text);
 },[props.data.games, text]);
 function handleClickOutside(event) {
   const modal = document.getElementsByClassName('search-game-popover')[0];
   if (modal !== event.target) {
       setFoundGames([]);
     }
  }
 useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
},[]);
  function setShowLoginModal() {
    props.data.loginModal === true ? props.setMainLogin(false) : props.setMainLogin(true);
  }
  function restoreModalOpen() {
    setRegisterModal(!registerModal);
    // props.setMainLogin();
  }
  function restorePassword(){
    if (restore !== undefined) {
      props.setShowLoading(true);
      axios({
            method: 'post',
            url: url + "/api/restore-password",
            data: {
              restore: restore
            }
          })
          .then(res => {
            if (res.data.status === 1) {
              setRegisterModal(false);
              setRestoreModal(true);
              props.setShowLoading(false);
            }
            if (res.data.error) {
              props.setShowLoading(false);
              setErrorMessage(res.data.error);
              setRestoreError(true);
            }
          }).catch(error => {
              setErrorMessage(error.response.data.message)
              setRestoreError(true);
              props.setShowLoading(false);
            });
    }

  }
  function restoreConfirm(){
    if (restore !== undefined &&
      newPassword !== undefined &&
      newPasswordConfirm !== undefined &&
      restore !== '' &&
      newPassword !== '' &&
      newPasswordConfirm !== '' &&
      newPasswordConfirm === newPassword) {
      props.setShowLoading(true);
      axios({
            method: 'post',
            url: url + "/api/restore-confirm",
            data: {
              password: newPassword,
              confirmation: confirmation,
              restore: restore
            }
          })
          .then(res => {
            setShowToast(true);
            setRestoreModal(false);
            props.setShowLoading(false);
          })
    }

  }
  function login(){
    axios.interceptors.response.use(response => {
      return response;
    }, function (error, response) {
        if (error.response.status === 422 || error.response.status === 403) {
          setLoginErrorMessage("Incorrect login or password");
          setLoginError(true);
          if (!Array.from(document.querySelectorAll('.modal-container')[0].classList).includes('incorrect')) {
              document.querySelectorAll('.modal-container')[0].classList.toggle('incorrect');
            }
        }
        props.setShowLoading(false);
       return Promise.reject(error);
     });
      var uuid = uuidv4();
      let requestMetadata = {UUID: uuid};
      async function sendInfo() {
      let fp = await FP.load({client: "UCmjTCW278UaQTDLjsMJ", region: "eu"});
      let response = await fp.send({tag: requestMetadata, callbackData: true});
      return response;
    }
    setLoginErrorMessage('');
    setLoginError(false);
    props.setShowLoading(true);
    axios({
          method: 'post',
          url: url + "/api/login",
          data: {
            password: password,
            login: username,
            uuid: uuid
          }
        })
        .then(res => {
          if (res.data.data.token) {
            props.setToken(res.data.data.token);
            console.log(props);
            props.data.loginModal === true ? props.setMainLogin(false) : props.setMainLogin(true);
            setUsername('');
            setPassword('');
            props.setUuid(uuid);
            history.push('/home')
          }
          return res.data
        })

        // .catch(error => {
        //   console.log(error);
        //   if (error.response.status === 422 || error.response.status === 403) {
        //     setLoginErrorMessage("Incorrect login or password");
        //     setLoginError(true);
        //     if (!Array.from(document.querySelectorAll('.modal-container')[0].classList).includes('incorrect')) {
        //         document.querySelectorAll('.modal-container')[0].classList.toggle('incorrect');
        //       }
        //   }
        //   props.setShowLoading(false);
        // });
        sendInfo();
      }
      function showGame(e){
        props.openGame(e);
        props.setShowLoading(true);
        axios({
                method: 'post',
                url: url + '/api/run-demo-game',
                data: {
                  id: +e,
                  lang: props.data.applang,
                  currency: props.data.userData.currency_id
                }
              })
              .then(res => {
                if (res.data.data.linkType === 'URL') {
                  setGameUrl(res.data.data.url);
                }
                if (res.data.data.linkType === 'HTML') {
                  setGameUrl('');
                  setHtml(res.data.data.html);
                }
                // props.setShowLoading(false);
                setIsOpen(true);
              })
      }
  return (
    <IonHeader id={'desktop-header'} className={(props.smallHeader === true ? 'minimize' : '')}>
      <div className={'toolbar-container'}>
        <IonButtons className={'only-mobile'} slot="start">
          <IonMenuButton />
        </IonButtons>
        <div className={'logo only-mobile'}>
          <IonItem
            lines="none"
            routerLink="/home"
            className="home-link">
          </IonItem>
        </div>
        <div className={'toolbar-contents'}>
          <div className={'logo-darkmode-container only-desktop'}>
            <div className={'top-container'}>
              <IonToggle
                checked={props.data.mode}
                onIonChange={() => toggleDarkMode()}
                className={'themeToggle'}
                ion-toggle-text
                ng-model="simple">
              </IonToggle>
              <div className={'header-search-container search-game'}>
                <IonSearchbar
                  placeholder="Search games"
                  className={'header-search'}
                  value={text}
                  onIonChange={e => setText(e.target.value)}>
                </IonSearchbar>
              { foundGames.length > 0 && <div className={'search-game-popover'}>
                  {
                    foundGames.map((el,i) => {
                    return (
                      <div onClick={() => showGame(el.id)} key={i} className={'search-game-link'} >
                        <div style={ {backgroundImage: `url(${el.banner_url})`}} className={'game-image'}></div>
                        <p>{el.name}</p>
                      </div>
                    )
                  })
                  }
                </div>}
              </div>
            </div>
            <div className={'logo'}>
            <IonItem
              lines="none"
              routerLink="/home"
              className="home-link">
            </IonItem>
            </div>
          </div>
          <MenuHeader isGame={location.pathname.includes('/game/') ? true : false} data={props.data} appPagesBottom={props.appPagesBottom}/>
          <UserActions
          changeAppLang={props.changeAppLang}
            clearState={props.clearState}
            setShowLoading={props.setShowLoading}
            setShowLoginModal={setShowLoginModal}
            className={'only-desktop'}
            data={props.data}/>
        </div>
        {/*<IonTitle className={"capitalize only-mobile"}>{props.page}</IonTitle>*/}
      </div>
        <GameModal setShowLoading={props.setShowLoading} html={html} setGameUrl={setGameUrl} setHtml={setHtml} token={props.data.token}  getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
      {/*Модальное окно логина*/}
      <IonModal isOpen={props.data.loginModal}
        cssClass='login-modal logreg'
        onDidDismiss={() => props.setMainLogin(false)}>
        <div className="modal-container">
          <div className="cards"></div>
          <div className="modal-container-title">
          Login
          </div>
          <form
            className="form-login"
            onSubmit={(e) => {e.preventDefault(); login()}}>
              <IonInput
                className={'header-search username'}
                type="text"
                value={username}
                placeholder="Username"
                onIonChange={e => setUsername(e.detail.value)}>
              </IonInput>
              <IonInput
                className={'header-search password'}
                type="password"
                value={password}
                placeholder="Password"
                onIonChange={e => setPassword(e.detail.value)}>
              </IonInput>
              <p className='incorrect-password'>Incorrect login or password</p>
              <button className="login-btn" type="submit">Login</button>
          </form>
          <p className="restore" onClick={() => restoreModalOpen()}>Forgot password</p>
        </div>
      </IonModal>
      {/*Модальное окно восстановления*/}
      <IonModal isOpen={registerModal}
        cssClass='login-modal logreg recvr'
        onDidDismiss={() => setRegisterModal(false)}>
        <div className="modal-container">
          <div className="cards"></div>
          <div className="modal-container-title">
          Password recovery
          </div>
          <p className="restore-p">Please enter your username or email address to recover.</p>
          <IonInput
            className={'header-search username'}
            type="text"
            placeholder={"Email or username"}
            value={restore}
            onIonChange={e => setRestore(e.detail.value)}>
          </IonInput>
          <div onClick={() => restorePassword()} className="login-btn">Restore password</div>
        </div>
      </IonModal>
      {/*Модальное окно смены пароля*/}
      <IonModal isOpen={restoreModal}
        cssClass='login-modal logreg recvr verific'
        onDidDismiss={() => setRestoreModal(false)}
        >
        <div className="modal-container">
          <div className="cards"></div>
          <div className="modal-container-title">
          Change Password
          </div>
          <IonInput
            className={'header-search username'}
            type="text"
            placeholder={"Email or username"}
            value={restore}
            onIonChange={e => setRestore(e.detail.value)}>
          </IonInput>
          <IonInput
            className={'header-search username'}
            type="text"
            autoComplete={'off'}
            placeholder={"Please enter verification code from Email"}
            value={confirmation}
            onIonChange={e => setConfirmation(e.detail.value)}>
          </IonInput>
          <IonInput
            className={'header-search username'}
            type="password"
            autoComplete={'off'}
            placeholder={"Please enter new password"}
            value={newPassword}
            onIonChange={e => setNewPassword(e.detail.value)}>
          </IonInput>
          <IonInput
            className={'header-search username'}
            type="password"
            autoComplete={'off'}
            placeholder={"Please repeat new password"}
            value={newPasswordConfirm}
            onIonChange={e => setNewPasswordConfirm(e.detail.value)}>
          </IonInput>
          <div onClick={() => restoreConfirm()} className="login-btn">Change password</div>
        </div>
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={"Your password has been successfully changed."}
        color='primary'
        duration={5000}
      />
      <IonToast
        isOpen={restoreError}
        onDidDismiss={() => setRestoreError(false)}
        message={errorMessage}
        color='danger'
        duration={5000}
      />
      <IonToast
        isOpen={loginError}
        onDidDismiss={() => {setLoginError(false)}}
        message={loginErrorMessage}
        color='danger'
        duration={5000}
      />
    </IonHeader>
  )
}
