import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonMenuToggle,
  IonSearchbar,
  IonHeader,
} from '@ionic/react';
import GameModal from './GameModal.js'
import {ReactComponent as New} from '../images/medals/new.svg';
import {ReactComponent as Bronze} from '../images/medals/bronze.svg';
import {ReactComponent as Silver} from '../images/medals/silver.svg';
import {ReactComponent as Gold} from '../images/medals/gold.svg';
import {ReactComponent as Platinum} from '../images/medals/platinum.svg';
import {ReactComponent as Diamond} from '../images/medals/diamond.svg';
import {ReactComponent as Exclusive} from '../images/medals/exclusive.svg';
import depositIcon from '../images/icons/deposit2.svg';
import loan from '../images/icons/loan.svg';
import withdrawIcon from '../images/icons/withdraw.svg';
import exchangeIcon from '../images/icons/exchange.svg';
import balanceIcon from '../images/icons/balance.svg';
import fundsSecurityIcon from '../images/icons/funds-security.svg';
import transactionsIcon from '../images/icons/transaction2.svg';
import casinoIcon from '../images/icons/casino.svg';
import userIcon from '../images/icons/user.svg';
import contactIcon from '../images/icons/profile.svg';
import limitIcon from '../images/icons/limit.svg';
import multipleIcon from '../images/icons/team.svg';
import verifyIcon from '../images/icons/check.svg';
import closeIcon from '../images/icons/close.svg';
import helpIcon from '../images/icons/question.svg';
import termsIcon from '../images/icons/terms.svg';
import about from '../images/icons/about.svg';
import cookie from '../images/icons/cookie.svg';
import privacy from '../images/icons/privacy.svg';
import kyc from '../images/icons/kyc.svg';
import contributions from '../images/icons/contributions.svg';
import disclaimer from '../images/icons/disclaimer.svg';
import responsible from '../images/icons/policy.svg';
import dataPolicy from '../images/icons/data-policy.svg';
import fundsSecurity from '../images/icons/funds-security.svg';
import { chevronForwardOutline,chevronBackOutline } from 'ionicons/icons';
import { menuController } from "@ionic/core";
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import url from '../axios.js';
import axios from 'axios';
import './css/Menu.css';
import ReactFlagsSelect from 'react-flags-select';

function Menu(props) {
  const location = useLocation();
  const [html, setHtml] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [text, setText] = useState('');
  const [lang, setLang] = useState('');
  const [bank, setBank] = useState(false);
  const [uHistory, setUhistory] = useState(false);
  const [details, setDetails] = useState(false);
  const [help, setHelp] = useState(false);
  const [newMenu, setNewMenu] = useState(false);
  const [onUser, setOnUser] = useState(false);
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [balanceSingle, setBalanceSingle] = useState(0);
  const [bonusTotal, setBonusTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [gameUrl, setGameUrl] = useState('');
  // let balanceTotal = 0;
  // let balanceSingle = 0;
  // let bonusTotal = 0;
  useEffect(() => {
    let balance = 0;
    let bonus = 0;
    let balSingle = 0;
    props.data.balance.forEach((item, i) => {
      balance += +item.ob_priv;
      bonus += +item.ob_priv_b;
    });
    props.data.balance.forEach((item, i) => {
      balSingle = item.ob
    });

    setBalanceTotal(balance);
    setBalanceSingle(balSingle);
    setBonusTotal(bonus);
  }, [props.data.balance])
  let userCurrency = props.data.userData.currency_id;
  const [foundGames, setFoundGames] = useState([]);


  function logout() {
      props.setShowLoading(true);
    axios({
          method: 'post',
          url: url + '/api/logout',
          headers: {
               'Authorization': `Bearer ${props.data.token}`,
           },
        })
        .then(res => {
          setShowPopover(false);
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
          props.clearState();
          props.setShowLoading(false);

        });
  }

  function getCurrencyValue(currency_id) {
    if (currency_id === 840) {
      return 'USD'
    }
    if (currency_id === 978) {
      return 'EUR'
    }
    if (currency_id === 643) {
      return 'RUR'
    }
    if (currency_id === 826) {
      return 'GBP'
    }
  }
  function getCurrency(currency_id){
    if (currency_id === 840) {
      return '$'
    }
    if (currency_id === 978) {
      return '€'
    }
    if (currency_id === 643) {
      return '₽'
    }
    if (currency_id === 826) {
      return '£'
    }
  }
  useEffect(() => {
    function searchGame(e) {
      let temp = [];
      if (e !== '') {
        props.data.games.forEach(item => {
          if (item.name.toLowerCase().indexOf(e.toLowerCase()) > -1) {
            temp.push(item);
          }
        });
        setFoundGames(temp);
      }
      if (e === '') {
        setFoundGames([]);
      }
    }
      searchGame(text);
   },[props.data.games, text]);
   function switchMenu() {
     setNewMenu(!newMenu)
     // document.getElementsByClassName('menu-content')[0].addClass('minimized');
   }
   function changeMenu() {
     if (bank === true) {
       setBank(false);
       setOnUser(true)
     };
     if (uHistory === true) {
       setUhistory(false);
       setOnUser(true);
     };
     if (details === true) {
       setDetails(false);
       setOnUser(true);
     }
     if (help === true) {
       setHelp(false);
       setOnUser(true);
     }
     if (onUser === true &&
     bank === false &&
     uHistory === false &&
     help === false ) {
       setOnUser(false);
       setNewMenu(!newMenu)
     }
   }
   function resetLinks() {
     setBank(false);
     setUhistory(false);
     setDetails(false);
     setHelp(false);
   }
   function showGame(e){
     props.openGame(e);
     props.setShowLoading(true);
     axios({
             method: 'post',
             url: url + '/api/run-demo-game',
             data: {
               id: +e,
               lang: props.data.applang,
               currency: props.data.userData.currency_id
             }
           })
           .then(res => {
             if (res.data.data.linkType === 'URL') {
               setGameUrl(res.data.data.url);
             }
             if (res.data.data.linkType === 'HTML') {
               setGameUrl('');
               setHtml(res.data.data.html);
             }
             // props.setShowLoading(false);
             setIsOpen(true);
           })
   }

   // props.data.balance.forEach((item, i) => {
   //   console.log(item.ob_priv);
   //   balanceTotal += item.ob_priv;
   //   bonusTotal += +item.ob_priv_b
   // });
   // props.data.balance.forEach((item, i) => {
   //   balanceSingle = item.ob;
   // });
   let vle = props.data.balance.length > 1 ? `${"~"+getCurrency(props.data.userData.currency_id)+" "+ balanceTotal.toFixed(2)}` : `${balanceSingle +" "+ getCurrency(userCurrency)}`;
  return (
    <IonMenu side="start" className={'sidebar mobile-view'} swipeGesture={false} contentId="main" type="overlay">

    <IonHeader>
      <div className={'search-game'}>
        <IonItem className={(newMenu === true ? "" : 'hide-this')} onClick={() => {resetLinks();changeMenu()}} routerDirection="none" lines="none" detail={false}>
          <IonIcon icon={chevronBackOutline} slot="start" />
          <IonLabel className={(bank === true ? "" : 'hide-this')} slot="end">Balance</IonLabel>
          <IonLabel className={(uHistory === true ? "" : 'hide-this')} slot="end">History</IonLabel>
          <IonLabel className={(details === true ? "" : 'hide-this')} slot="end">Account Details</IonLabel>
          <IonLabel className={(help === true ? "" : 'hide-this')} slot="end">Help & Info</IonLabel>
          <IonLabel className={(onUser === true ? "" : 'hide-this')} slot="end">User</IonLabel>
        </IonItem>
        <IonSearchbar
          placeholder="Search games"
          className={'header-search '  + (newMenu === true ? "hide-this" : '')}
          value={text}
          onIonChange={e => setText(e.target.value)}>
        </IonSearchbar>
      { foundGames.length > 0 && <div className={'search-game-popover'}>
          {
            foundGames.map((el,i) => {
            return (
              <div onClick={() => {showGame(el.id);menuController.toggle()}} key={i} className={'search-game-link'}>
                <div style={ {backgroundImage: `url(${el.banner_url})`}} className={'game-image'}>
                </div>
                <p>{el.name}</p>
              </div>
            )
          })
          }
        </div>}
      </div>
    </IonHeader>
      <IonContent>
      <div className={'header-search-container lang-select'}>
        <ReactFlagsSelect
        className={'header-search country'}
         searchable={false}
         value={lang}
         onSelect={e => setLang(e)}
         defaultCountry="GB"
         customLabels={{"GB": "English", "ES": "Spanish","IT": "Italian","DE": "German","NL": 'Dutch',"PL": "Polish", "PT": "Portuguese", "RU": "Russian", "TR": "Turkish", "FR": "French", "CN": "Chinese","JP": "Japanese", "AE": "Arabic"}}
         countries={["GB","ES", "IT", "DE", "NL", "PL", "PT", "RU", "TR", "FR", "CN", "JP", "AE"]}
         searchPlaceholder="Search for a country" />
      </div>

      <div className={'menu-content ' + (newMenu === true ? "minimized" : '')}>
      {
      props.data.token == null &&
      <div className={'login-register-container unlogged'}>
        <IonItem
          button
          onClick={() => {menuController.toggle(); props.setMainLogin(true)}}
          className="login-btn">
          <IonLabel>Login</IonLabel>
        </IonItem>
        <IonItem
          button
          onClick={() => menuController.toggle()}
          routerLink="/register"
          className="register-btn">
          <IonLabel>Sign Up</IonLabel>
        </IonItem>
      </div>
        }

        {
        props.data.token !== null &&
        <div className={'login-register-container logged'}>
        {props.data.userData.player_status_id === 1 && <New/>}
        {props.data.userData.player_status_id === 2 && <Bronze/>}
        {props.data.userData.player_status_id === 3 && <Silver/>}
        {props.data.userData.player_status_id === 4 && <Gold/>}
        {props.data.userData.player_status_id === 5 && <Platinum/>}
        {props.data.userData.player_status_id === 6 && <Diamond/>}
        {props.data.userData.player_status_id === 7 && <Exclusive/>}
        <IonItem className='user-menu' onClick={() => {switchMenu();setOnUser(true)}} routerDirection="none" lines="none" detail={false}>
        <div className='userpic'
          style={ props.data.userData.avatar === null ? {backgroundImage: `url(${props.data.defaultAvatar})`} : {backgroundImage: `url(${props.data.userData.avatar})`} }
          >
          </div>
          <p>{props.data.userData.first_name + ' ' + props.data.userData.last_name || 'guest'}</p>
          <IonIcon icon={chevronForwardOutline} slot="end" />
        </IonItem>

        </div>
          }
          {
          props.data.token !== null &&
          <div className="header-menu-bottom">
          <p className={"header-balance " + (location.pathname.includes('/game/') ?  'in-game-balance' : "")}>
            Balance {location.pathname.includes('/game/') ?  'in game window' : vle}
          </p>
          {  !location.pathname.includes('/game/') && <IonSelect
              onIonChange={e => vle = props.data.userData.currency_id}
              value={''}
              className={'header-search country cur'}
              interface="action-sheet"
              cancelText='Back'
              onIonCancel={() => menuController.toggle()}
              onClick={() => menuController.toggle()}>
              {
                props.data.balance.map(el => {
                  if (el.currency_id === props.data.userData.currency_id && el.credit_time !== null) {
                    return (
                      <IonSelectOption key={'loan'} disabled={true} value={el.currency_id}>Loan: {el.credit_value} {getCurrency(el.currency_id)}</IonSelectOption>
                    )
                  }
                })
              }
              {
                props.data.balance.map((el, i) => {
                  return (
                      <IonSelectOption key={i} disabled={true} value={el.currency_id}>Deposit in {getCurrencyValue(el.currency_id)}: {el.ob} {getCurrency(el.currency_id)}</IonSelectOption>
                  )
                })
              }
                {props.data.balance.length > 1 && <IonSelectOption disabled={true} value='total'>~{getCurrency(props.data.userData.currency_id)}  {balanceTotal.toFixed(2)}</IonSelectOption>}
            </IonSelect>}
            <Link onClick={() => menuController.toggle()} to={'/user/deposit-money'} className={'deposit-money-button'}>Deposit</Link>

          </div>
          }
          {
            props.data.token !== null &&
            <div className="header-menu-bottom">
              <p className="menu-bonus">Bonus {getCurrency(props.data.userData.currency_id)}  {bonusTotal.toFixed(2)}</p>
            </div>
          }
        <IonList id="inbox-list">
          <IonMenuToggle autoHide={true}>
            <IonItem routerLink={'/games/video-slots'} routerDirection="none" lines="none" detail={false}>
              <div className={'slots-img'}></div>
              <IonLabel>Slots</IonLabel>
            </IonItem>
            <IonItem routerLink={'/games/table-games'} routerDirection="none" lines="none" detail={false}>
              <div className={'games-img'}></div>
              <IonLabel>Games</IonLabel>
            </IonItem>
            <IonItem routerLink={'/games/live-casino'} routerDirection="none" lines="none" detail={false}>
              <div className={'live-img'}></div>
              <IonLabel>Live</IonLabel>
            </IonItem>
            {/*<IonItem routerLink={'/games/lotto'} routerDirection="none" lines="none" detail={false}>
              <div className={'lotto-img'}></div>
              <IonLabel>Bingo</IonLabel>
            </IonItem>*/}
            <IonItem routerLink={'/games/other-games'} routerDirection="none" lines="none" detail={false}>
              <div className={'other-img'}></div>
              <IonLabel>Other</IonLabel>
            </IonItem>
            <IonItem href={'https://www.sbet.biz/home'} target="_blank" routerDirection="none" lines="none" detail={false}>
              <div className={'box-img'}></div>
              <IonLabel>Bets</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <div className={'login-register-container outer-links'}>
          <a href="./" className="gplay">
            <img src={require('../images/gplay.svg')} alt="Google Play"></img>
          </a>
          <a href="./" className="appstore">
            <img src={require('../images/appstore.svg')} alt="App Store"></img>
          </a>

        </div>
      </div>
      {props.data.token !== null && <div className={'new-menu ' + (newMenu === true ? "new-menu-active" : 'hide-this')}>
        <div className={"links-to-menu " + (onUser ===true ? "new-menu-active" : 'hide-this')}>
        <IonItem className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerLink={'/user'} lines="none" detail={false}>
          <IonLabel>Statistics</IonLabel>
          <IonIcon icon={chevronForwardOutline} slot="end" />
        </IonItem>
          <IonItem className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {setBank(!bank);setOnUser(false)}} routerDirection="none" lines="none" detail={false}>
            <IonLabel>Balance</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {setUhistory(!bank);setOnUser(false)}} routerDirection="none" lines="none" detail={false}>
            <IonLabel>History</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {setDetails(!bank);setOnUser(false)}} routerDirection="none" lines="none" detail={false}>
            <IonLabel>Account Details</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  className={(newMenu === true ? "new-menu-active" : '')}onClick={() => {setHelp(!bank);setOnUser(false)}} routerDirection="none" lines="none" detail={false}>
            <IonLabel>Help & Info</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  className={(newMenu === true ? "new-menu-active" : '')}onClick={() => {setHelp(!bank);setOnUser(false)}} routerDirection="none" lines="none" detail={false}>
          <p className="logout logout-btn" onClick={() => {menuController.toggle(); setShowPopover(true)}}>Logout</p>
          </IonItem>
        </div>
        <div className={(bank === true ? "new-menu-active" : 'hide-this')}>
          <IonItem  routerLink={'/user/deposit-money'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={depositIcon} slot="start" />
            <IonLabel>Deposit Money</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/transfer-money'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={exchangeIcon} slot="start" />
            <IonLabel>Exchange Money</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/withdraw-money'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={withdrawIcon} slot="start" />
            <IonLabel>Withdraw Money</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/balance'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={balanceIcon} slot="start" />
            <IonLabel>Balance</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/loan'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={loan} slot="start" />
            <IonLabel>Loan request</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/funds-security'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={fundsSecurityIcon} slot="start" />
            <IonLabel>Funds Security</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
        </div>
        <div className={(uHistory === true ? "new-menu-active" : 'hide-this')}>
          <IonItem  routerLink={'/user/transactions'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={transactionsIcon} slot="start" />
            <IonLabel>Transactions</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/casino-and-games'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={casinoIcon} slot="start" />
            <IonLabel>Casino & Games</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
        </div>
        <div className={(details === true ? "new-menu-active" : 'hide-this')}>
          <IonItem  routerLink={'/user/personal-details'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={userIcon} slot="start" />
            <IonLabel>Personal Details</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/contact-details'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={contactIcon} slot="start" />
            <IonLabel>Contact Details</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/games-limit'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={limitIcon} slot="start" />
            <IonLabel>Games Limit</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/multiple-logins'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={multipleIcon} slot="start" />
            <IonLabel>Multiple Logins</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/verify-account'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={verifyIcon} slot="start" />
            <IonLabel>Verify Account</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/user/close-account'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={closeIcon} slot="start" />
            <IonLabel>Close Account</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
        </div>
        <div className={(help === true ? "new-menu-active" : 'hide-this')}>
          <IonItem  routerLink={'/documents/help'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={helpIcon} slot="start" />
            <IonLabel>Help</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/terms'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={termsIcon} slot="start" />
            <IonLabel>Terms & Conditions</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/about'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={about} slot="start" />
            <IonLabel>About Us</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/cookie-policy'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={cookie} slot="start" />
            <IonLabel>Cookie policy</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/policy'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={privacy} slot="start" />
            <IonLabel>Privacy policy</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/kyc-policy'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={kyc} slot="start" />
            <IonLabel>KYC Policy</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/contributions'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={disclaimer} slot="start" />
            <IonLabel>Contributions</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/disclaimer'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={contributions} slot="start" />
            <IonLabel>Disclaimer</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/responsible-policy'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={responsible} slot="start" />
            <IonLabel>Responsible gambling policy</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
          <IonItem  routerLink={'/documents/data-protection'} className={(newMenu === true ? "new-menu-active" : '')} onClick={() => {menuController.toggle();resetLinks();switchMenu()}} routerDirection="none" detail={false}>
            <IonIcon className={'no-margin1'} icon={dataPolicy} slot="start" />
            <IonLabel>Data protection policy</IonLabel>
            <IonIcon icon={chevronForwardOutline} slot="end" />
          </IonItem>
        </div>
      </div>}

      </IonContent>


      {/*Модальное окно logout*/}
      <IonModal isOpen={showPopover}
        cssClass='login-modal logout logreg'
        onDidDismiss={() => setShowPopover(false)}>
        <div className="modal-container">
        <div className="cards"></div>
          <div className="modal-container-title">
          Logout
          </div>
          <p className="logout-p">Are you sure you want to log out?</p>
          <div onClick={() => logout()} className="login-btn">Logout</div>

        </div>
      </IonModal>
      <GameModal setShowLoading={props.setShowLoading} html={html}  setGameUrl={setGameUrl} setHtml={setHtml} token={props.data.token}  getInfo={props.getInfo} gameUrl={gameUrl} isOpen={isOpen} setIsOpen={setIsOpen} />
    </IonMenu>
  );
};

export default Menu;
