import { IonModal } from "@ionic/react";
import React, { useEffect, useState, memo } from "react";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet";

const GameModal = memo(props => {
  const [isPresented, setIspresented] = useState(false);
  useEffect(() => {
    if (props.isOpen === true) {
      document.getElementsByTagName("body")[0].classList.add("hide-chat");
    }
  }, [props.isOpen]);

  function closeGame() {
    document.getElementsByTagName("body")[0].classList.remove("hide-chat");
    props.setIsOpen(false);
    if (props.token !== null && props.token !== undefined) {
      props.getInfo(props.token);
    }
    props.setGameUrl("");
    setIspresented(false);
    props.setHtml("");
  }
  return (
    <IonModal
      isOpen={props.isOpen}
      cssClass="game-modal"
      onDidDismiss={() => closeGame()}
      onDidPresent={() => setIspresented(true)}
    >
      <div className="close-gme" onClick={() => closeGame()} />
      {props.gameUrl !== "" && (
        <Iframe
          onLoad={()=> props.setShowLoading(false)}
          url={props.gameUrl.toString()}
          width="100%"
          height="100%"
          id="gameOpened"
          className="game-opened"
          display="initial"
          position="relative"
        />
      )}
      {props.html !== "" && isPresented === true && (
        <div className="html-frame">
          <div
            className="html-frame"
            dangerouslySetInnerHTML={{ __html: props.html }}
          />
        <Helmet onLoad={()=> props.setShowLoading(false)}>
            {props.html.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gm) !==null &&
              Array.from(props.html.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gm)).map((el,i) => {
                if(props.html.match('egamingsStartNetEnt')!== null) {
                  if (/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] !== '') {
                      return (
                          <script type="text/javascript" key={i}>
                            {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] + ' egamingsStartNetEnt()'}
                          </script>
                          // + ' egamingsStartNetEnt()'
                      )
                    } if (/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] === '') {
                      return(
                        <script key='added' type="text/javascript" src="https://egamings-static-test.casinomodule.com/gameinclusion/library/gameinclusion.js"></script>
                      )
                    }
                  }
                  else {
                    return (
                        <script type="text/javascript">
                          {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1]}
                        </script>
                    )
                  }

              }
            )
            }

            </Helmet>
        </div>
      )}
    </IonModal>
  );
});
export default GameModal;
