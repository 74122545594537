import React from 'react';
import { IonItem,IonLabel } from '@ionic/react';
import  DataChart  from '../../../components/DataChart.js';
import '../../../components/css/Main.css';
import '../../../components/css/UserCabinet.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import '../../../components/css/Daypicker.css';
import moment from 'moment';
import { Card, CardBody } from "shards-react";
import axios from 'axios';
import url from '../../../axios.js'
import { formatDate, parseDate } from 'react-day-picker/moment';
import transactionIcon from '../../../images/icons/transaction2.svg';

export default class Transactions extends React.Component{
  constructor(props) {
  super(props);
  this.handleFromChange = this.handleFromChange.bind(this);
  this.handleToChange = this.handleToChange.bind(this);
  this.state = {
    from: new Date(moment().subtract(7, 'days')),
    to: new Date(),
    transactions: [],
    totalLength: [],
    showFrom: 0,
    count: 0
  };
}
componentDidMount() {
  if (this.props.data.token !==null) {
    this.props.setShowLoading(true);
      axios({
            method: 'post',
            url: url + "/api/history-transactions",
            headers: {
                 'Authorization': `Bearer ${this.props.data.token}`,
             },
            data: {
              date_from: moment().subtract(7, 'days').unix(),
              date_to: moment().add(7, 'days').unix()
            }
          })
          .then(res => {
            this.props.setShowLoading(false);
            this.setState({transactions: res.data.data.history,
              count: res.data.data.history.length});
              let start = 1;
              let result = [];
              while (start <= Math.ceil(this.state.transactions.length/10)) {
                result.push(start++);
              }
              this.setState({totalLength: result})
              // console.log(res.data.data.history);
          })
  }
}
show() {
  if (this.state.from !== undefined && this.state.to !== undefined) {
    this.props.setShowLoading(true);
      axios({
            method: 'post',
            url: url + "/api/history-transactions",
            headers: {
                 'Authorization': `Bearer ${this.props.data.token}`,
             },
            data: {
              date_from: moment(this.state.from).unix(),
              date_to: moment(this.state.to).unix()
            }
          })
          .then(res => {
            this.props.setShowLoading(false);
            this.setState({transactions: res.data.data.history,
              count: res.data.data.history.length});
              let start = 1;
              let result = [];
              while (start <= Math.ceil(this.state.transactions.length/10)) {
                result.push(start++);
              }
              this.setState({totalLength: result})
              // console.log(res.data.data.history);
          })
  }

}

showFromMonth() {
  const { from, to } = this.state;
  if (!from) {
    return;
  }
  if (moment(to).diff(moment(from), 'months') < 2) {
    this.to.getDayPicker().showMonth(from);
  }
}

handleFromChange(from) {
  this.setState({ from });
}

handleToChange(to) {
  this.setState({ to }, this.showFromMonth);
}
paginate(item) {
  this.setState({showFrom: item})
}
    render(){
      function pickCurrency(currency_id) {
        if (currency_id === 840) {
          return '$'
        }
        if (currency_id === 978) {
          return '€'
        }
        if (currency_id === 643) {
          return '₽'
        }
        if (currency_id === 826) {
          return '£'
        }
      }
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      const modifiersStyles = {
        birthday: {
          color: 'white',
          backgroundColor: '#ffc107',
        },
        thursdays: {
          color: '#ffc107',
          backgroundColor: '#fffdee',
        },
      };
      return (
        <div className="transactions">
          <div className="shade">
            <p className="top-shade">
              <img alt='transactions' src={transactionIcon} />
              <span>History</span>
            </p>
            <p className="bottom-shade">Transactions</p>
          </div>
          <div className='transactions-container'>
            <p className="title">
              <span>History Transactions</span>
            </p>
            <div className="time-buttons">
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date(moment().subtract(1, 'days')));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>Day</IonLabel>
              </IonItem>
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date(moment().subtract(7, 'days')));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>Week</IonLabel>
              </IonItem>
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date(moment().subtract(1, 'months')));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>Month</IonLabel>
              </IonItem>
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date(moment().subtract(1, 'year')));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>Year</IonLabel>
              </IonItem>
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date(moment().subtract(3, 'months')));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>Quarter</IonLabel>
              </IonItem>
              <IonItem
                button
                onClick={() => {
                  let promise = new Promise((resolve) => {
                    this.handleFromChange(new Date('2020-01-01 17:13:37'));
                    resolve(this.state.from);
                  });
                  promise
                    .then(result => {
                      this.show();
                    })
                }}
                className="register-btn">
                <IonLabel>All time</IonLabel>
              </IonItem>
            </div>
            <div className="InputFromTo">
              <DayPickerInput
                value={from}
                placeholder="From"
                format="LL"
                modifiersStyles={modifiersStyles}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange}
              />
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={el => (this.to = el)}
                  value={to}
                  placeholder="To"
                  format="LL"
                  modifiersStyles={modifiersStyles}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2,
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
            </div>
            <IonItem
              button
              onClick={() => this.show()}
              className="register-btn submit">
              <IonLabel>Show</IonLabel>
            </IonItem>
            {/*card*/}
            <DataChart transactions={this.state.transactions} data={this.props.data} amount={0} label1={'deposit'} label2={'withdraw'} value1={0} value2={0} />
            {
              this.state.transactions.length === 0 &&
              <IonItem className='no-transactions'>
                <IonLabel>No transactions during this period.</IonLabel>
              </IonItem>
            }
            {this.state.transactions.length > 0 && <Card className="card-table">
            <CardBody className="p-0 pb-3">
               <table className="table mb-0">
                <thead className="card-header-main">
                  <tr>
                    <th scope="col" className="border-0">
                      Date & Time
                    </th>
                    <th scope="col" className="border-0">
                      Operation
                    </th>
                    <th scope="col" className="border-0">
                    Amount
                    </th>
                    <th scope="col" className="border-0">
                      From/To
                    </th>
                    <th scope="col" className="border-0">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.transactions.map((el,i) => {
                    if (i>= this.state.showFrom && i < this.state.showFrom + 10){
                    return (
                      <tr key={i}>
                        <td>{moment(el.created_at).format('L')}, {moment(el.created_at).format('LT')}</td>
                        <td>{el.operation}</td>
                        <td>{el.amount} {pickCurrency(el.currency_id)}</td>
                        <td>{el.payment_system_name}</td>
                        <td>{el.status}</td>
                      </tr>
                    )
                  }
                  return null;
                  })
                }
                </tbody>
              </table>
            </CardBody>
            {this.state.transactions.length > 10  && <div className="card-paginator">
                <div onClick={() => this.paginate(0)} className="arrow">{'<'}</div>

                {
                  this.state.totalLength.map((el,i)=> {
                    return(<div onClick={() => this.paginate(i*10)} key={i}>{i+1}</div>)
                  })
                }

                <div onClick={() => this.paginate((this.state.count - (this.state.count - (this.state.totalLength.length -1))) * 10)} className="arrow">{'>'}</div>
              </div>}
            </Card>
          }
            {/*card-end*/}
          </div>
        </div>
      );
    }
};
